import { Facebook, Instagram } from '@components/icons'
import Link from 'next/link'
interface Props {
  width?: number
  height?: number
  fill?: string
}

const SocialIcons = ({ width, height, fill }: Props) => {
  const socialData = {
    facebook: {
      link: 'https://www.facebook.com/HoskingsJewellers/',
    },
    instagram: {
      link: 'https://www.instagram.com/hoskingsjewellers/',
    },
  }
  const { facebook, instagram } = socialData

  return (
    <div className="flex gap-4">
      {facebook?.link && (
        (<Link
          href={facebook?.link || '/'}
          target="_blank"
          rel="noreferrer"
          className="hover:opacity-75">

          <Facebook width={width} height={height} fill={fill} />

        </Link>)
      )}

      {instagram?.link && (
        (<Link
          href={instagram?.link || '/'}
          target="_blank"
          rel="noreferrer"
          className="hover:opacity-75">

          <Instagram width={width} height={height} fill={fill} />

        </Link>)
      )}
    </div>
  );
}
export default SocialIcons
