const FulfilmentInfoIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.3925 13.5H9.7425V8.1H8.3925V13.5ZM9 6.435C9.21 6.435 9.38625 6.3675 9.52875 6.2325C9.67125 6.0975 9.7425 5.925 9.7425 5.715C9.7425 5.505 9.67125 5.325 9.52875 5.175C9.38625 5.025 9.21 4.95 9 4.95C8.79 4.95 8.61375 5.025 8.47125 5.175C8.32875 5.325 8.2575 5.505 8.2575 5.715C8.2575 5.925 8.32875 6.0975 8.47125 6.2325C8.61375 6.3675 8.79 6.435 9 6.435ZM9 18C7.77 18 6.6075 17.7638 5.5125 17.2912C4.4175 16.8187 3.46125 16.1737 2.64375 15.3562C1.82625 14.5387 1.18125 13.5825 0.70875 12.4875C0.23625 11.3925 0 10.2225 0 8.9775C0 7.7475 0.23625 6.585 0.70875 5.49C1.18125 4.395 1.82625 3.4425 2.64375 2.6325C3.46125 1.8225 4.4175 1.18125 5.5125 0.70875C6.6075 0.23625 7.7775 0 9.0225 0C10.2525 0 11.415 0.23625 12.51 0.70875C13.605 1.18125 14.5575 1.8225 15.3675 2.6325C16.1775 3.4425 16.8187 4.395 17.2912 5.49C17.7638 6.585 18 7.755 18 9C18 10.23 17.7638 11.3925 17.2912 12.4875C16.8187 13.5825 16.1775 14.5387 15.3675 15.3562C14.5575 16.1737 13.605 16.8187 12.51 17.2912C11.415 17.7638 10.245 18 9 18ZM9.0225 16.65C11.1375 16.65 12.9375 15.9038 14.4225 14.4113C15.9075 12.9188 16.65 11.1075 16.65 8.9775C16.65 6.8625 15.9075 5.0625 14.4225 3.5775C12.9375 2.0925 11.13 1.35 9 1.35C6.885 1.35 5.08125 2.0925 3.58875 3.5775C2.09625 5.0625 1.35 6.87 1.35 9C1.35 11.115 2.09625 12.9188 3.58875 14.4113C5.08125 15.9038 6.8925 16.65 9.0225 16.65Z"
        fill="#464544"
      />
    </svg>
  )
}

export default FulfilmentInfoIcon
