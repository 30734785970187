import Checkbox from '@components/common/Checkbox'
import { LeftArrow, LegacyCross } from '@components/icons'
import { Button, Input } from '@components/ui'
import useSignup from '@framework/auth/use-signup'
import cn from 'clsx'
import { validate } from 'email-validator'
import { FC, useCallback, useEffect, useState } from 'react'
import s from './SignUpSidebarView.module.css'

interface props {
  open: boolean
  defaultEmail: string
  onClose: () => void
  openLoginSidebar: () => void
}

const SignUpSidebarView: FC<props> = ({
  open = false,
  onClose,
  defaultEmail = '',
  openLoginSidebar,
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [news, setNews] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const closeSignupView = () => onClose()

  useEffect(() => {
    setEmail(defaultEmail)
  }, [defaultEmail])
  const signup = useSignup()

  const handleSignup = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()

    if (!dirty && !disabled) {
      setDirty(true)
      handleValidation()
    }

    try {
      setLoading(true)
      setMessage('')
      await signup({
        email,
        firstName,
        lastName,
        password,
      })
      setLoading(false)
      closeSignupView()
    } catch (e: any) {
      setMessage(e.errors[0].message)
      setLoading(false)
    }
  }

  const handleValidation = useCallback(() => {
    // Test for Alphanumeric password
    const validPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(password)

    // Unable to send form unless fields are valid.
    if (dirty) {
      setDisabled(!validate(email) || password.length < 7 || !validPassword)
    }
  }, [email, password, dirty])

  useEffect(() => {
    handleValidation()
  }, [handleValidation])

  return (
    <div className={cn(s.root, { [s.signupSidebarOpen]: open })}>
      <div className="sticky top-0 z-10">
        <header className="py-4 px-6 lg:py-6  flex items-center justify-start bg-accent-0 w-full z-10 border-b border-b-ui-grey-25">
          <button
            onClick={closeSignupView}
            aria-label="Close"
            className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none block sm:hidden"
          >
            <LeftArrow className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
          </button>
          <div className="text-brand-dark-grey text-body3 font-bold sm:text-label sm:text-left text-center flex-1">
            CREATE A PROFILE
          </div>
          <button
            onClick={closeSignupView}
            aria-label="Close"
            className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none ml-auto"
          >
            <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
          </button>
        </header>
      </div>

      <form onSubmit={handleSignup} className="flex flex-col px-6">
        <p className="text-body1 mt-8 mb-4">
          Just a few more details and you’re all set up
        </p>
        {message && (
          <div className="px-4 py-3 w-full text-red appearance-none  border border-grey rounded-2 mb-4">
            {message}
          </div>
        )}
        <Input
          type="email"
          placeholder="Email"
          onChange={setEmail}
          value={email}
        />
        <Input
          placeholder="First name"
          className="mt-4"
          onChange={setFirstName}
          focus={open}
        />
        <Input
          placeholder="Last name"
          className="mt-4"
          onChange={setLastName}
        />
        <Input
          type="password"
          className="mt-4"
          placeholder="Password"
          onChange={setPassword}
        />

        <Checkbox
          label={'Keep me up to date with news and offers'}
          checked={news}
          handleChange={setNews}
          labelStyling="text-body2 font-medium"
        />

        <div className="w-full flex flex-col">
          <Button
            variant="slim"
            type="submit"
            loading={loading}
            disabled={disabled}
          >
            Create profile
          </Button>
        </div>
      </form>
    </div>
  )
}

export default SignUpSidebarView
