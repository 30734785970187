import { LeftArrow, LegacyCross } from '@components/icons'
import { Button, Input } from '@components/ui'
import useRecoverCustomer from '@framework/customer/use-recover-customer'
import cn from 'clsx'
import { validate } from 'email-validator'
import { FC, useCallback, useEffect, useState } from 'react'
import s from './ForgotSidebarView.module.css'

interface props {
  open: boolean
  defaultEmail: string
  onClose: () => void
  openLoginSidebar: () => void
}

const ForgotSidebarView: FC<props> = ({
  open = false,
  onClose,
  defaultEmail = '',
  openLoginSidebar,
}) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [sucessMessage, setSucessMessage] = useState('')
  const [dirty, setDirty] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const recoverCustomer = useRecoverCustomer()

  useEffect(() => {
    setEmail(defaultEmail)
  }, [defaultEmail])

  const handleResetPassword = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()

    if (!dirty && !disabled) {
      setDirty(true)
      handleValidation()
    }

    try {
      setLoading(true)
      setMessage('')
      setSucessMessage('')
      await recoverCustomer({ email })
      setLoading(false)
      setSucessMessage(
        `We've sent you an email with a link to update your password.`
      )
    } catch (e: any) {
      setMessage(e.errors[0].message)
      setLoading(false)
    }
  }

  const handleValidation = useCallback(() => {
    // Unable to send form unless fields are valid.
    if (dirty) {
      setDisabled(!validate(email))
    }
  }, [email, dirty])

  return (
    <div className={cn(s.root, { [s.loginSidebarOpen]: open })}>
      <div className="sticky top-0 z-10">
        <header className="py-4 px-6 lg:py-6  flex items-center justify-start bg-accent-0 w-full z-10 border-b border-b-ui-grey-25">
          <button
            onClick={onClose}
            aria-label="Close"
            className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none block sm:hidden"
          >
            <LeftArrow className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
          </button>
          <div className="text-brand-dark-grey text-body3 font-bold sm:text-label sm:text-left text-center flex-1">
            FORGOT PASSWORD
          </div>
          <button
            onClick={onClose}
            aria-label="Close"
            className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none ml-auto"
          >
            <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
          </button>
        </header>
      </div>
      <form onSubmit={handleResetPassword} className="flex flex-col px-6">
        <div className="flex flex-col">
          {sucessMessage && (
            <div className="px-4 py-3 w-full text-green appearance-none  border border-grey rounded-2 mb-4">
              {sucessMessage}
            </div>
          )}
          {message && (
            <div className="px-4 py-3 w-full text-red appearance-none  border border-grey rounded-2 mb-4">
              {message}
            </div>
          )}

          <Input
            className="mt-8"
            placeholder="Email"
            onChange={setEmail}
            type="email"
            value={email}
            focus={open}
          />
          <Button
            className="mt-6"
            variant="slim"
            type="submit"
            loading={loading}
            disabled={disabled}
          >
            Recover Password
          </Button>

          <div className="flex mt-4 items-center justify-center">
            <span
              className="px-0 border-b border-b-ui-dark-grey text-ui-dark-grey cursor-pointer text-body2"
              onClick={onClose}
            >
              Go back
            </span>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ForgotSidebarView
