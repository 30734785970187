import { checkoutDetailsFragment } from "../queries/get-checkout-query";
const checkoutAttributesMutation = /* GraphQL */ `
  mutation checkoutAttributesUpdateV2(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
  ) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        ...checkoutDetails
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }

  ${checkoutDetailsFragment}
`;
export default checkoutAttributesMutation;
