const PlusV2 = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3125 5.3125C10.3125 5.13991 10.1726 5 10 5C9.82741 5 9.6875 5.13991 9.6875 5.3125V9.6875H5.3125C5.13991 9.6875 5 9.82741 5 10C5 10.1726 5.13991 10.3125 5.3125 10.3125H9.6875V14.6875C9.6875 14.8601 9.82741 15 10 15C10.1726 15 10.3125 14.8601 10.3125 14.6875V10.3125H14.6875C14.8601 10.3125 15 10.1726 15 10C15 9.82741 14.8601 9.6875 14.6875 9.6875H10.3125V5.3125Z"
        fill="black"
      />
    </svg>
  )
}

export default PlusV2
