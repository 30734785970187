import {
  ArrowRight,
  Chat,
  Envelope,
  FAQ,
  Location,
  PhoneIcon,
} from '@components/icons'
import { useCustomer } from '@framework/customer'
import Image from "next/legacy/image"
import Link from 'next/link'
import ProfileSidebarItem from '../ProfileSidebarItem'
import { LinkType } from '../ProfileSidebarItem/ProfileSidebarItem'
type HelpSupportProps = {
  heading?: string
  showFindStoreItem?: boolean
  blog_data?: any
}

const HelpSupport = ({
  heading,
  showFindStoreItem,
  blog_data,
}: HelpSupportProps) => {
  const { data: isCustomerLoggedIn } = useCustomer()

  const placeholderImg = '/product-img-placeholder.svg'
  const helpdata = {
    title: heading || 'HELP & SUPPORT',
    phone: '1800 819 796',
    email: 'hello@hoskings.com.au',
    findstoretitle: 'Find your closest store',
    findstorelink: '/help/store-location',
    faqtitle: 'FAQs',
    faqlink: '/help/faqs',
    livechat: 'Live chat',
  }
  return (
    <div className="px-6 flex flex-col">
      {heading ? (
        <p className="font-bold mb-4 text-body2">{heading}</p>
      ) : (
        helpdata.title && (
          <p className="font-bold mb-4 text-body2">{helpdata.title}</p>
        )
      )}

      {blog_data?.length > 0 &&
        blog_data?.map((item: any, index: number) => {
          return (
            <div className="w-full flex gap-5 mb-6" key={index}>
              <Image
                width={142}
                height={185}
                src={item.image?.url || placeholderImg}
                alt={item.image?.alt_text || item.title}
                unoptimized
              />
              <div className="flex-1 flex flex-col">
                <p className="text-body2">{item.title}</p>
                <p className="text-body2 mt-2">{item.content}</p>
                <Link href={item.link} className="mt-2 text-body2 flex items-center gap-1">

                  {item.linktext}
                  <ArrowRight className={'w-3 h-3'} />

                </Link>
              </div>
            </div>
          );
        })}

      <ProfileSidebarItem
        icon={PhoneIcon}
        link={'tel:' + helpdata?.phone}
        title={helpdata?.phone}
        underline={false}
        showChevRight={false}
        linkType={LinkType.PHONE}
      />
      <ProfileSidebarItem
        icon={Envelope}
        link={'mailto:' + helpdata?.email}
        title={helpdata?.email}
        underline={false}
        linkType={LinkType.EMAIL}
      />
      {(isCustomerLoggedIn || showFindStoreItem) && (
        <ProfileSidebarItem
          icon={Location}
          link={helpdata?.findstorelink}
          title={helpdata?.findstoretitle}
          underline={false}
        />
      )}

      <ProfileSidebarItem
        icon={FAQ}
        link={helpdata?.faqlink}
        title={helpdata?.faqtitle}
        underline={false}
      />
      {/* <ProfileSidebarItem
        icon={Chat}
        title={helpdata?.livechat}
        underline={false}
      /> */}
    </div>
  );
}

export default HelpSupport
