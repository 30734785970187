import { Container } from '@components/ui'
import Link from 'next/link'
import { FC } from 'react'
import styles from './TopMenu.module.css'

interface Props {}

const TopMenu: FC<Props> = ({}) => {
  return (
    <div className="bg-brand-polar-white py-1.5 md:py-0">
      <Container>
        <div className="flex justify-center items-center md:justify-between">
          <div className="flex flex-col md:flex-row relative w-full h-8 items-center justify-center">
            <div className={styles.textFirst}>
              <div className="body-2-bold text-text-subdued">
                Free express shipping over $79 (excludes giftware items)
              </div>
              <div className="text-text-subdued body-2">
                Delivered in 2–8 business days
              </div>
            </div>
            <div className={styles.textSecond}>
              <div className="body-2-bold text-text-subdued">
                Free click & collect on all orders{' '}
              </div>
              <div className="text-text-subdued body-2">
                Ready in 1–5 business days
              </div>
            </div>
          </div>

          <div className="md:flex hidden text-text-subdued body-2">
            {/*<div className='hover:opacity-75 hover:cursor-pointer'>Blog</div>*/}
            {/*<div className='pl-6 hover:opacity-75 hover:cursor-pointer'>Catalogue</div>*/}
            <Link
              href="/hoskings-jewellers"
              className="pl-6 hover:opacity-75 hover:cursor-pointer"
            >
              About
            </Link>
            <Link
              href="/stores"
              className="pl-6 hover:opacity-75 hover:cursor-pointer"
            >
              Stores
            </Link>
            <span className="pl-6 whitespace-nowrap">1800 819 796</span>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TopMenu
