import React, { FC, useState, useEffect, useCallback } from 'react'

import { useAddItem } from '@framework/cart'

import { useUI } from '@components/ui/context'
import shopifyBuyClient from '@lib/shopify-buy-client'

interface Props {
  giftWrapping: any
}
const AddGiftWrappingControl: FC<Props> = ({ giftWrapping }) => {
  const [giftProduct, setGiftProduct] = useState<any>(null)
  const [isLoadingGiftWrapping, setIsLoadingGiftWrapping] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const [loading, setLoading] = useState(false)
  const addItem = useAddItem()

  const { openSidebar } = useUI()

  const lineItemsToAdd = {
    productId: giftProduct?.variants[0].id,
    variantId: giftProduct?.variants[0].id,
    quantity: 1,
  }

  const fetchGiftProduct = useCallback(
    (productId: string) => {
      setIsLoadingGiftWrapping(true)
      shopifyBuyClient.product
        .fetch(productId)
        .then((product: any) => {
          setGiftProduct(product)
          setIsLoadingGiftWrapping(false)
        })
        .catch((err: Error) => {
          setError(err)
          setIsLoadingGiftWrapping(false)
        })
    },
    [shopifyBuyClient]
  )

  useEffect(() => {
    if (giftWrapping) {
      const productId = `gid://shopify/Product/${giftWrapping.shopifyGiftWrappingProductId}`
      fetchGiftProduct(productId)
    }
  }, [giftWrapping])

  const addToCart = async () => {
    setLoading(true)
    try {
      await addItem({
        productId: String(lineItemsToAdd.productId),
        variantId: String(lineItemsToAdd.variantId),
      })
      openSidebar()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  if (isLoadingGiftWrapping) {
    return null
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  return (
    <div className="flex justify-between bg-white p-5 items-center mb-8 mx-4 border border-ui-grey-25 rounded-[4px]">
      <div className="flex items-center">
        {/* image */}
        <div className="mr-3">
          <img
            src={giftProduct.images[0].src}
            height={48}
            width={48}
            alt={giftWrapping.noteMessage}
          />
        </div>

        {/* note message */}
        <p className="text-13 mr-3">{giftWrapping.noteMessage}</p>
      </div>

      {/* add button */}
      <button
        onClick={addToCart}
        className="border border-brand-dark-grey px-3 py-0 leading-none text-13 h-9 rounded-sm"
      >
        Add
      </button>
    </div>
  )
}

export default AddGiftWrappingControl
