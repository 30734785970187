import { SHOPIFY_CHECKOUT_ID_COOKIE } from "./const";
import { handler as useCart } from "./cart/use-cart";
import { handler as useAddItem } from "./cart/use-add-item";
import { handler as useUpdateItem } from "./cart/use-update-item";
import { handler as useRemoveItem } from "./cart/use-remove-item";
import { handler as useUpdateCart } from "./cart/use-update-cart";
import { handler as useCustomer } from "./customer/use-customer";
import { handler as useRecoverCustomer } from "./customer/use-recover-customer";
import { handler as useResetCustomer } from "./customer/use-reset-customer";
import { handler as useActivateCustomer } from "./customer/use-activate-customer";
import { handler as useSearch } from "./product/use-search";
import { handler as useLogin } from "./auth/use-login";
import { handler as useLogout } from "./auth/use-logout";
import { handler as useSignup } from "./auth/use-signup";
import { handler as useCustomerOrders } from "./customer/use-customer-orders";
import { handler as useUpdateCustomer } from "./customer/use-update-customer";
import fetcher from "./fetcher";
export const shopifyProvider = {
    locale: "en-us",
    cartCookie: SHOPIFY_CHECKOUT_ID_COOKIE,
    fetcher,
    cart: {
        useCart,
        useAddItem,
        useUpdateItem,
        useRemoveItem,
        useUpdateCart
    },
    customer: {
        useCustomer,
        useRecoverCustomer,
        useResetCustomer,
        useActivateCustomer,
        useUpdateCustomer,
        useCustomerOrders
    },
    products: {
        useSearch
    },
    auth: {
        useLogin,
        useLogout,
        useSignup
    }
};
