import LoginView from '@components/auth/LoginView'
import CartSidebarView from '@components/cart/CartSidebarView'
import CheckoutSidebarView from '@components/checkout/CheckoutSidebarView'
import { CheckoutProvider } from '@components/checkout/context'
import PaymentMethodView from '@components/checkout/PaymentMethodView'
import ShippingView from '@components/checkout/ShippingView'
import { Footer, Navbar } from '@components/common'
import HotJarFeedbackButton from '@components/common/HotJarFeedbackButton'
import SearchBarView from '@components/common/Searchbar'
import { MenuSidebarView } from '@components/common/UserNav'
import MobileProfileSidebar from '@components/profile/MobileProfileSidebar'
import ProfileSidebarView from '@components/profile/ProfileSidebarView'
import ServicePortalSidebarView from '@components/profile/ServicePortalSidebar'
import { LoadingDots, Sidebar } from '@components/ui'
import { useUI } from '@components/ui/context'
import { CommerceProvider } from '@framework'
import {
  StoreFinderSidebar,
  StoreSidebarProvider,
} from '@lib/store-finder-sidebar'
import { StoreLocation } from '@lib/store-finder-sidebar/types'
import cn from 'clsx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Scripts from 'scripts'
import { ReactNode, useMemo } from 'react'
import s from './Layout.module.css'
import { TagPromoOffProvider } from '@lib/TagPromoOffProvider'

const Loading = () => (
  <div className="w-80 h-80 flex items-center text-center justify-center p-3">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const SignUpView = dynamic(() => import('@components/auth/SignUpView'), {
  ...dynamicProps,
})

const ForgotPassword = dynamic(
  () => import('@components/auth/ForgotPassword'),
  {
    ...dynamicProps,
  }
)

const FeatureBar = dynamic(() => import('@components/common/FeatureBar'), {
  ...dynamicProps,
})

const Modal = dynamic(() => import('@components/ui/Modal'), {
  ...dynamicProps,
  ssr: false,
})

interface Props {
  pageProps: {
    tagPromoOff?: any
    categories?: any
    mainNav?: any
    footer?: any
    giftWrapping?: any
    giftWithPurchase?: any
    giftWithPurchaseConfig?: any
    fit?: boolean
    stores?: StoreLocation[]
    announcement?: {
      data: {
        Title: string
        description: string
        fullWidth: string
        slices: any[]
      }
    }
  }
  children: ReactNode
}

const ModalView: React.FC<{ modalView: string; closeModal(): any }> = ({
  modalView,
  closeModal,
}) => {
  return (
    <Modal onClose={closeModal}>
      {modalView === 'LOGIN_VIEW' && <LoginView />}
      {modalView === 'SIGNUP_VIEW' && <SignUpView />}
      {modalView === 'FORGOT_VIEW' && <ForgotPassword />}
    </Modal>
  )
}

const ModalUI: React.FC = () => {
  const { displayModal, closeModal, modalView } = useUI()
  return displayModal ? (
    <ModalView modalView={modalView} closeModal={closeModal} />
  ) : null
}

const SidebarView: React.FC<{
  sidebarView: string
  closeSidebar(): any
  menuItems: any
  giftWrapping: any
  giftWithPurchase: any
  giftWithPurchaseConfig: any
}> = ({
  sidebarView,
  closeSidebar,
  menuItems,
  giftWrapping,
  giftWithPurchase,
  giftWithPurchaseConfig,
}) => {
  let slideInFrom = 'slideInFromRight'
  let classNames = 'w-full'
  if (sidebarView === 'MOBILE_MENU_VIEW') slideInFrom = 'slideInFromLeft'
  if (sidebarView === 'CART_VIEW') classNames = 'w-full md:w-92'
  if (sidebarView === 'STORE_SIDEBAR') {
    classNames = 'w-full md:w-92'
    slideInFrom = 'slideInFromLeft'
  }
  if (sidebarView === 'PROFILE_SIDEBAR_VIEW') classNames = 'w-full md:w-92'
  if (sidebarView === 'SERVICE_PORTAL_VIEW') classNames = 'w-full md:w-92'
  if (sidebarView === 'MOBILE_PROFILE_SIDEBAR_VIEW')
    slideInFrom = 'slideInFromBottom'
  if (sidebarView === 'SEARCH_VIEW') slideInFrom = 'slideInFromTop'
  return (
    <Sidebar
      onClose={closeSidebar}
      slideInFromClassName={slideInFrom}
      classNames={classNames}
    >
      {sidebarView === 'PROFILE_SIDEBAR_VIEW' && (
        <ProfileSidebarView onClose={closeSidebar} />
      )}
      {sidebarView === 'SEARCH_VIEW' && (
        <SearchBarView onClose={closeSidebar} />
      )}
      {sidebarView === 'MOBILE_PROFILE_SIDEBAR_VIEW' && (
        <MobileProfileSidebar onClose={closeSidebar} title="Your Profile" />
      )}
      {sidebarView === 'CART_VIEW' && (
        <CartSidebarView
          onClose={closeSidebar}
          giftWrapping={giftWrapping}
          giftWithPurchase={giftWithPurchase}
          giftWithPurchaseConfig={giftWithPurchaseConfig}
        />
      )}
      {sidebarView === 'STORE_SIDEBAR' && (
        <StoreFinderSidebar onClose={closeSidebar} />
      )}
      {sidebarView === 'SHIPPING_VIEW' && <ShippingView />}
      {sidebarView === 'PAYMENT_VIEW' && <PaymentMethodView />}
      {sidebarView === 'CHECKOUT_VIEW' && <CheckoutSidebarView />}
      {sidebarView === 'MOBILE_MENU_VIEW' && (
        <MenuSidebarView menuItems={menuItems} onClose={closeSidebar} />
      )}
      {sidebarView === 'SERVICE_PORTAL_VIEW' && (
        <ServicePortalSidebarView onClose={closeSidebar} />
      )}
    </Sidebar>
  )
}

const SidebarUI: React.FC<{
  menuItems: any
  giftWrapping: any
  giftWithPurchase: any
  giftWithPurchaseConfig: any
}> = ({
  menuItems,
  giftWrapping,
  giftWithPurchase,

  giftWithPurchaseConfig,
}) => {
  const { displaySidebar, closeSidebar, sidebarView, setSideBarIsClosing } =
    useUI()

  const handleClose = () => {
    setSideBarIsClosing(true)
    setTimeout(function () {
      setSideBarIsClosing(false)
      closeSidebar()
    }, 200)
  }

  return displaySidebar ? (
    <SidebarView
      menuItems={menuItems}
      sidebarView={sidebarView}
      giftWrapping={giftWrapping}
      giftWithPurchase={giftWithPurchase}
      giftWithPurchaseConfig={giftWithPurchaseConfig}
      closeSidebar={handleClose}
    />
  ) : null
}

const Layout: React.FC<Props> = ({ children, pageProps }) => {
  const { locale = 'en-AU' } = useRouter()

  const announcement = useMemo(
    () =>
      pageProps.announcement && {
        title: pageProps.announcement?.data?.Title,
        description: pageProps.announcement?.data?.description,
      },
    [pageProps.announcement]
  )

  const giftWrapping = useMemo(
    () =>
      pageProps.giftWrapping && {
        status: pageProps.giftWrapping?.data?.status,
        shopifyGiftWrappingProductId:
          pageProps.giftWrapping.data?.shopifyGiftWrappingProductId,
        noteMessage: pageProps.giftWrapping?.data?.noteMessage,
        noteMessagePlaceholder:
          pageProps.giftWrapping?.data?.noteMessagePlaceholder,
      },
    [pageProps.giftWrapping]
  )

  const giftWithPurchase = pageProps.giftWithPurchase?.data.slices

  const giftWithPurchaseConfig = pageProps.giftWithPurchaseConfig

  return (
    <>
      <HotJarFeedbackButton />
      <CommerceProvider locale={locale}>
        <TagPromoOffProvider config={pageProps?.tagPromoOff}>
          <StoreSidebarProvider>
            <div className={cn(s.root)}>
              <Navbar
                links={[]}
                navItems={pageProps?.mainNav}
                announcement={announcement}
              />
              <main className="fit">{children}</main>
              <Footer footer={pageProps?.footer} />

              <CheckoutProvider>
                <SidebarUI
                  menuItems={pageProps?.mainNav}
                  giftWrapping={giftWrapping}
                  giftWithPurchase={giftWithPurchase}
                  giftWithPurchaseConfig={giftWithPurchaseConfig}
                />
              </CheckoutProvider>
            </div>
          </StoreSidebarProvider>
        </TagPromoOffProvider>
      </CommerceProvider>
      <Scripts />
    </>
  )
}

export default Layout
