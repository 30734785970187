import getAllForIndex from '@lib/algolia/get-all-for-index'
import { getDeploymentUrl } from '@lib/get-deployment-url'

async function getAllStores(
  attributesToRetrieve: string[] = [
    'title',
    'address_line_1',
    'address_line_2',
    'suburb',
    'state',
    'city',
    'normal_hours',
    'special_hourss',
    'postcode',
    'lat',
    'lng',
    'location_id',
    'phone_number',
    'email',
    'click_and_collect',
  ]
) {
  if (!process.env.NEXT_PUBLIC_ALGOLIA_STORE_PRIMARY_INDEX)
    throw new Error(
      'process.env.NEXT_PUBLIC_ALGOLIA_STORE_PRIMARY_INDEX is missing'
    )

  const filters = 'active:true'

  return getAllForIndex(
    process.env.NEXT_PUBLIC_ALGOLIA_STORE_PRIMARY_INDEX,
    attributesToRetrieve,
    filters
  )
}

export default getAllStores

export async function getAllStoresViaProxy() {
  const res = await fetch(`${getDeploymentUrl()}/api/algolia/get-all-stores`)

  return await res.json()
}
