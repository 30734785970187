import Link from '@components/ui/Link'
import { ImageField, RichTextField } from '@prismicio/types'
import config from '@config/seo_meta.json'
import Image from "next/legacy/image"
import { PrismicRichText } from '@prismicio/react'
import { FC } from 'react'
import { useUI } from "@components/ui";

interface props {
  title: string
  link?: string
  text?: RichTextField
  image?: ImageField
  handleCloseMegaMenu: Function
}

const NavFeaturedCallout: FC<props> = ({ title, link, text, image , handleCloseMegaMenu }) => {
  const { closeSidebar } = useUI()
  const closeMenu = () => {
    handleCloseMegaMenu()
    closeSidebar()
  }

  const markup = (
    <div className="space-y-2">
      {image?.url && (
        <Image
          src={image?.url}
          alt={image?.alt || config.openGraph.images[0].alt}
          height={256}
          width={192}
          unoptimized={true}
        />
      )}

      <div className="hover:opacity-75">
        <div className="body-3-bold text-dark-grey uppercase">{title}</div>
        <div className="body-2 text-dark-grey">
          <PrismicRichText field={text} />
        </div>
      </div>
    </div>
  )

  if (link) {
    return <Link onClick={closeMenu} href={link} prefetch={false}>{markup}</Link>
  }

  return markup
}

export default NavFeaturedCallout
