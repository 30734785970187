import {
  PropsWithChildren,
  useContext,
  createContext,
  useCallback,
} from 'react'

type TagPromoOffContextType = {
  withTagPromoOff: (
    tags: string[],
    price: number,
    compare_at_price: number
  ) => {
    price: number
    compare_at_price: number
    isPromoOff: boolean
    isOnSale: boolean
  }
}

const TagPromoOffContext = createContext<TagPromoOffContextType>({
  withTagPromoOff: (_, price, compare_at_price) => ({
    price,
    compare_at_price,
    isPromoOff: false,
    isOnSale: !!compare_at_price && compare_at_price > price,
  }),
})

export const TagPromoOffProvider = ({
  children,
  config,
}: PropsWithChildren<{ config: any }>) => {
  const withTagPromoOff = useCallback(
    (tags: string[], price: number, compare_at_price: number) => {
      const isPremium = tags.includes('Premium:yes')
      if (isPremium || price < compare_at_price)
        return { price, compare_at_price, isPromoOff: false, isOnSale: true }

      const matched = config?.data?.slices?.find(
        (slice: any) =>
          tags &&
          tags
            .map((tag: string) => tag?.toLowerCase())
            .includes(slice?.primary?.tagName?.toLowerCase())
      )

      const discount = matched?.primary?.discount ?? 0

      if (discount > 0) {
        return {
          price: (price * (100 - discount)) / 100.0,
          compare_at_price: price,
          isPromoOff: true,
          isOnSale: true,
        }
      }

      return { price, compare_at_price, isPromoOff: false, isOnSale: false }
    },
    [config]
  )

  return (
    <TagPromoOffContext.Provider value={{ withTagPromoOff }}>
      {children}
    </TagPromoOffContext.Provider>
  )
}

export const useTagPromoOff = () => useContext(TagPromoOffContext)
