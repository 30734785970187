import Client from 'shopify-buy'

const shopifyBuyClient = Client.buildClient({
  //@ts-ignore
  domain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
  //@ts-ignore
  storefrontAccessToken:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
})

export default shopifyBuyClient
