import { LeftArrow, LegacyCross } from '@components/icons'
import { Button, Input } from '@components/ui'
import useLogin from '@framework/auth/use-login'
import cn from 'clsx'
import { validate } from 'email-validator'
import { FC, useCallback, useEffect, useState } from 'react'
import s from './LoginSidebarView.module.css'

interface props {
  open: boolean
  defaultEmail: string
  onClose: () => void
  openSignupSidebar: () => void
  openForgotSidebar: () => void
}

const LoginSidebarView: FC<props> = ({
  open = false,
  onClose,
  defaultEmail = '',
  openSignupSidebar,
  openForgotSidebar,
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [dirty, setDirty] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const closeLoginView = () => {
    onClose()
  }
  useEffect(() => {
    setEmail(defaultEmail)
  }, [defaultEmail])
  const login = useLogin()

  const handleLogin = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()

    if (!dirty && !disabled) {
      setDirty(true)
      handleValidation()
    }

    try {
      setLoading(true)
      setMessage('')
      await login({
        email,
        password,
      })
      setLoading(false)
      closeLoginView()
    } catch (e: any) {
      setMessage(e.errors[0].message)
      setLoading(false)
      setDisabled(false)
    }
  }

  const handleValidation = useCallback(() => {
    // Test for Alphanumeric password
    const validPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(password)

    // Unable to send form unless fields are valid.
    if (dirty) {
      setDisabled(!validate(email) || password.length < 7 || !validPassword)
    }
  }, [email, password, dirty])

  useEffect(() => {
    handleValidation()
  }, [handleValidation])

  return (
    <div className={cn(s.root, { [s.loginSidebarOpen]: open })}>
      <div className="sticky top-0 z-10">
        <header className="py-4 px-6 lg:py-6  flex items-center justify-start bg-accent-0 w-full z-10 border-b border-b-ui-grey-25">
          <button
            onClick={closeLoginView}
            aria-label="Close"
            className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none block sm:hidden"
          >
            <LeftArrow className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
          </button>
          <div className="text-brand-dark-grey text-body3 font-bold sm:text-label sm:text-left text-center flex-1">
            LOG IN
          </div>
          <button
            onClick={closeLoginView}
            aria-label="Close"
            className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none ml-auto"
          >
            <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
          </button>
        </header>
      </div>

      <form onSubmit={handleLogin} className="flex flex-col px-6">
        <p className="text-body1 mt-8 mb-4">Please log in to your profile</p>
        {message && (
          <div className="px-4 py-3 w-full text-red appearance-none  border border-grey rounded-2 mb-4">
            {message}
          </div>
        )}
        <Input
          type="email"
          placeholder="Email address"
          value={email}
          onChange={setEmail}
        />
        <Input
          className="mt-4"
          type="password"
          placeholder="Password"
          onChange={setPassword}
          focus={open}
        />
        <div className="flex mt-3">
          <span
            className="px-0 border-b border-b-ui-dark-grey text-ui-dark-grey cursor-pointer text-body2"
            onClick={openForgotSidebar}
          >
            Forgot password?
          </span>
        </div>
        <Button
          className="mt-6"
          variant="slim"
          type="submit"
          loading={loading}
          disabled={disabled}
        >
          Log in
        </Button>
      </form>
    </div>
  )
}

export default LoginSidebarView
