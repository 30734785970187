import { Mail, MinusV2, MyStore, PhoneV2, PlusV2 } from '@components/icons'
import cn from 'classnames'
import Link from 'next/link'
import { useState } from 'react'
import { StoreLocation } from '../types'

interface Props extends StoreLocation {
  onSetStore?: () => void
  isCurrent?: boolean
  onShowNumber?: (s: StoreLocation) => void
}

const StoreCard = (props: Props) => {
  const {
    distance,
    isCurrent = false,
    onSetStore,
    email,
    slug,
    title,
    address,
    phone_number,
    open_hours = [],
    onShowNumber,
  } = props
  const [isShowHours, setShowHours] = useState(false)
  const [isShowNumber, setShowNumber] = useState(false)

  const handleTogglePhoneNumber = () => {
    setShowNumber(true)
    onShowNumber && onShowNumber(props)
  }

  return (
    <div
      className={cn('flex flex-col border p-4 text-13', {
        'border-ui-darkest-grey': isCurrent,
        'border-ui-grey-25': !isCurrent,
      })}
    >
      <div className="flex justify-between align-middle items-center font-semibold mb-2">
        <span>{title}</span>
        {distance && (
          <span>
            {Number.parseInt(distance).toLocaleString('en-US', {
              style: 'unit',
              unit: 'kilometer',
            })}
          </span>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <span>{address}</span>
        <div className="flex items-center">
          <Mail className="h-4 w-4 mr-2" />
          {email}
        </div>
        {/* <div className="flex items-center">
          <PhoneV2 className="h-4 w-4 mr-2" />
          {phone_number}
        </div> */}
        {/* <div className="flex items-center">
          <Link href={`/stores/${slug}`} passHref>
            <a target="_blank" className="flex items-center justify-center">
              <MyStore className="h-4 w-4 mr-2" /> Visit store page
            </a>
          </Link>
        </div> */}
      </div>

      <div className="flex mt-4 gap-4">
        <div className="flex items-center flex-shrink-0 flex-1 w-full justify-center">
          {isCurrent ? (
            <button
              className="bg-brand-dark-grey w-full text-white py-1 rounded-sm hover:bg-brand-darkest-grey"
              onClick={onSetStore}
            >
              My Store
            </button>
          ) : (
            <button
              className="w-full py-1 rounded-sm border-brand-dark-grey border"
              onClick={onSetStore}
            >
              Set as my store
            </button>
          )}
        </div>
        <div className="flex items-center flex-shrink-0 flex-1 w-full justify-center">
          <button
            onClick={() => setShowHours((prev) => !prev)}
            className="flex items-center"
          >
            <span className="mr-1 hover:underline">Store details</span>
            {isShowHours ? <MinusV2 /> : <PlusV2 />}
          </button>
        </div>
      </div>
      {isShowHours && (
        <div className="flex flex-col mt-4">
          <div className="mb-4">
            {open_hours?.map((open_hour, key) => (
              <div
                key={key}
                className="flex justify-between text-subdued font-sans font-normal text-13 leading-5"
              >
                <span>{open_hour.day}</span>
                <span>{open_hour.time}</span>
              </div>
            ))}
          </div>
          {onShowNumber && (
            <button
              className="w-full py-1 rounded-sm border-brand-dark-grey border uppercase flex items-center justify-center hover:bg-ui-dark-grey hover:text-white"
              onClick={handleTogglePhoneNumber}
            >
              <PhoneV2 />
              {isShowNumber ? (
                <a href={`tel:${phone_number}`}>{phone_number}</a>
              ) : (
                `Show number`
              )}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default StoreCard
