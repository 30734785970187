import { Hit } from '@commerce/types/product'
import { ProductCard } from '@components/product'
import cn from 'clsx'
import { connectHits } from 'react-instantsearch-dom'
import s from './SearchHits.module.css'

interface Props {
  onClose: () => void
  hits: []
}

const SearchHits = ({ onClose, hits }: Props) => {
  if (hits.length === 0) return <></>
  const filteredHits = hits
    .filter((hit: any, index) => {
      //only displays a sold out product if it's the closest search match
      return hit?.variants_inventory_count || index === 0
    })
    .slice(0, 8) // limit to 8 products displayed
  if (filteredHits.length === 0) return <></>
  return (
    <div
      className={cn(
        'grid grid-cols-2 md:grid-cols-4 gap-x-5 md:gap-x-8 gap-y-8 md:gap-y-10 max-w-4xl content-center mx-auto px-4 py-6 md:py-10',
        s.hitsContainer
      )}
    >
      {filteredHits.map((hit: Hit) => {
        // const product: Product.Product = normalizeAlgoliaHit(hit, 'AU')
        const images: any = [{ url: hit.image }]

        const brandTags = hit?.named_tags?.Brand
        const brand = typeof brandTags === 'string' ? brandTags : brandTags?.[0]

        return (
          <div onClick={onClose} key={hit.id}>
            <ProductCard
              id={hit.objectID}
              price={hit.price}
              compareAtPrice={hit.compare_at_price}
              key={hit.id}
              name={hit?.title}
              images={images}
              link={`/${hit.handle}`}
              brand={brand?.replace(/_/g, ' ')}
              namedTags={hit?.named_tags}
              isSoldOut={hit.variants_inventory_count === 0}
              tags={hit?.tags}
            />
          </div>
        )
      })}
      <style jsx>
        {`
          .square {
            width: 100%;
            background-color: #f2ead7;
          }
          .square:after {
            content: '';
            display: block;
            padding-bottom: 100%;
          }
        `}
      </style>
    </div>
  )
}

export default connectHits(SearchHits)
