import { FC, useEffect, useState } from 'react'
import SearchBarClose from '@components/icons/SearchBarClose'

interface Props {}

const HotJarFeedbackButton: FC<Props> = () => {
  const [showFeedbackButton, setShowFeedbackButton] = useState(true)
  const [showWidget, setShowWidget] = useState(false)

  useEffect(() => {
    const widgetContainer = document.getElementById('_hj_feedback_container')
    const widgetCloseButton = widgetContainer?.querySelector(
      '[aria-label="Close"]'
    )
    const handleClose = () => {
      setShowFeedbackButton(true)
      setShowWidget(false)
    }
    if (widgetCloseButton) {
      widgetCloseButton.addEventListener('click', handleClose)
    }
    return () => {
      if (widgetCloseButton) {
        widgetCloseButton.addEventListener('click', handleClose)
      }
    }
  }, [showFeedbackButton])

  const handleButtonClick = () => {
    const defaultHotJarFeedbackButton = document
      .getElementById('_hj_feedback_container')
      ?.querySelector(
        '[class*="MinimizedWidgetMiddle__label"]'
      ) as HTMLElement | null
    if (defaultHotJarFeedbackButton) {
      defaultHotJarFeedbackButton.click()
      setShowFeedbackButton(false)
      setShowWidget(true)
    }
  }

  return (
    <>
      {showFeedbackButton && !showWidget && (
        <div className="customFeedbackButton">
          <div className="flex flex-col">
            <button
              className="bg-brand-polar-white transition-all duration-300 flex items-center justify-center rounded-tr-lg w-7 h-7"
              onClick={() => setShowFeedbackButton(false)}
            >
              <div className="laptop:hidden">
                <SearchBarClose fill="#464544" width={16} height={16} />
              </div>
              <div className="hidden laptop:block">
                <SearchBarClose fill="#464544" width={16} height={16} />
              </div>
            </button>
            <button
              className="flex justify-center items-center outline-none bg-brand-chrome text-white w-7 h-20 transition-all duration-300 text-body2 rounded-br-lg"
              onClick={handleButtonClick}
            >
              <div className="feedbackText font-medium">Feedback</div>
            </button>
          </div>
        </div>
      )}
      <style jsx>
        {`
          .customFeedbackButton {
            position: fixed;
            z-index: 9999;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          .feedbackText {
            writing-mode: vertical-rl;
            transform: scale(-1, -1);
          }
        `}
      </style>
    </>
  )
}

export default HotJarFeedbackButton
