const Logo = ({ ...props }) => {
  return (
    <svg
      width="172"
      height="24"
      viewBox="0 0 172 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="matrix(0.5972222 0 0 0.5972222 -0 0.055555582)">
        <g>
          <path
            d="M35.0039 27.5891L28.3539 27.5891L28.3539 20.7557L6.67835 20.7557L6.67835 27.5891L0.0283203 27.5891L0.0283203 0.130585L6.67835 0.130585L6.67835 13.614L28.3539 13.614L28.3539 0.130585L35.0039 0.130585L35.0039 27.5891z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M79.4402 13.8289C 79.4402 17.7616 78.2356 21.0161 75.8265 23.5956C 73.4185 26.1751 70.3789 27.4654 66.7109 27.4654L66.7109 27.4654L51.5172 27.4654C 47.8471 27.4654 44.7988 26.1751 42.3724 23.5956C 39.9426 21.0161 38.7305 17.7616 38.7305 13.8289C 38.7305 9.85712 39.9426 6.58094 42.3724 3.99928C 44.7978 1.41979 47.8471 0.130585 51.5172 0.130585L51.5172 0.130585L66.7109 0.130585C 70.3799 0.130585 73.4196 1.41979 75.8265 3.99928C 78.2356 6.58094 79.4402 9.85712 79.4402 13.8289zM72.7891 13.8289C 72.7891 11.8278 72.2345 10.2434 71.1255 9.08012C 70.0175 7.9168 68.5254 7.31344 66.6523 7.27112L66.6523 7.27112L51.5172 7.27112C 49.6431 7.31344 48.1521 7.9168 47.0441 9.08012C 45.935 10.2434 45.3816 11.8278 45.3816 13.8289C 45.3816 15.8311 45.935 17.4144 47.0441 18.5788C 48.1521 19.7432 49.6442 20.3237 51.5172 20.3237L51.5172 20.3237L66.6523 20.3237C 68.5254 20.3237 70.0175 19.7432 71.1255 18.5788C 72.2345 17.4144 72.7891 15.8311 72.7891 13.8289z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M118.2 18.8468C 118.2 21.3091 117.435 23.3612 115.906 25.0031C 114.378 26.6461 112.467 27.4665 110.173 27.4665L110.173 27.4665L85.0006 27.4665L85.0006 20.3248L110.115 20.3248C 111.07 20.3248 111.547 19.8322 111.547 18.8479C 111.547 17.8636 111.07 17.371 110.115 17.371L110.115 17.371L91.2512 17.371C 88.9951 17.371 87.0939 16.5538 85.5453 14.9152C 83.9967 13.2798 83.2219 11.2147 83.2219 8.71985C 83.2219 6.26625 83.9967 4.22175 85.5453 2.58638C 87.0939 0.950999 88.9951 0.131683 91.2512 0.131683L91.2512 0.131683L116.48 0.131683L116.48 7.27221L91.3087 7.27221C 90.3516 7.27221 89.8752 7.75512 89.8752 8.71985C 89.8752 9.72691 90.3516 10.2283 91.3087 10.2283L91.3087 10.2283L110.173 10.2283C 112.467 10.2283 114.378 11.0508 115.906 12.6927C 117.435 14.3324 118.2 16.3845 118.2 18.8468z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M158.279 27.5891L150.367 27.5891C 147.995 24.5104 145.895 22.3769 144.06 21.1876C 141.765 19.7508 138.975 19.0324 135.688 19.0324L135.688 19.0324L129.438 19.0324L129.438 27.5902L122.787 27.5902L122.787 0.130585L129.438 0.130585L129.438 11.8908L135.688 11.8908C 139.165 11.8908 142.167 10.7611 144.689 8.50389C 146.601 6.77953 148.608 3.98951 150.711 0.130585L150.711 0.130585L158.108 0.130585C 155.354 6.73938 151.532 11.7269 146.641 15.0921C 151.913 17.9646 155.794 22.1306 158.279 27.5891z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M169.516 27.5891L162.865 27.5891L162.865 0.130585L169.516 0.130585L169.516 27.5891z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M209.079 27.5891L204.424 27.5891L180.754 10.2358L180.754 27.5891L174.105 27.5891L174.105 0.130585L178.749 0.130585L202.428 17.1214L202.428 0.130585L209.079 0.130585L209.079 27.5891z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M247.781 27.4654L225.592 27.4654C 221.921 27.4654 218.874 26.1751 216.446 23.5956C 214.018 21.0161 212.805 17.7616 212.805 13.8289C 212.805 9.85712 214.02 6.58094 216.446 3.99928C 218.872 1.41979 221.921 0.130585 225.592 0.130585L225.592 0.130585L247.781 0.130585L247.781 7.27112L225.592 7.27112C 223.759 7.27112 222.275 7.86471 221.149 9.04974C 220.02 10.2358 219.455 11.8289 219.455 13.8289C 219.455 15.8311 220.009 17.4144 221.119 18.5788C 222.227 19.7432 223.718 20.3237 225.591 20.3237L225.591 20.3237L241.129 20.3237L241.129 17.3688L225.416 17.3688L225.416 10.2261L247.779 10.2261L247.779 27.4654L247.781 27.4654z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M287.286 18.8468C 287.286 21.3091 286.521 23.3612 284.993 25.0031C 283.465 26.6461 281.553 27.4665 279.26 27.4665L279.26 27.4665L254.089 27.4665L254.089 20.3248L279.204 20.3248C 280.157 20.3248 280.638 19.8322 280.638 18.8479C 280.638 17.8636 280.157 17.371 279.204 17.371L279.204 17.371L260.34 17.371C 258.082 17.371 256.181 16.5538 254.634 14.9152C 253.084 13.2798 252.31 11.2147 252.31 8.71985C 252.31 6.26625 253.084 4.22175 254.634 2.58638C 256.181 0.950999 258.082 0.131683 260.34 0.131683L260.34 0.131683L285.568 0.131683L285.568 7.27221L260.397 7.27221C 259.439 7.27221 258.963 7.75512 258.963 8.71985C 258.963 9.72691 259.439 10.2283 260.397 10.2283L260.397 10.2283L279.261 10.2283C 281.554 10.2283 283.466 11.0508 284.994 12.6927C 286.522 14.3346 287.286 16.3845 287.286 18.8468z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M209.214 37.4719L210.413 37.4719C 210.473 38.0199 210.662 38.4171 210.983 38.6688C 211.303 38.9173 211.791 39.0454 212.446 39.0454C 212.866 39.0454 213.226 38.9835 213.529 38.8577C 213.832 38.7339 214.074 38.5484 214.254 38.2988C 214.377 38.1317 214.466 37.9255 214.519 37.6791C 214.572 37.4361 214.599 37.0193 214.599 36.4257L214.599 36.4257L214.599 36.2717L214.599 31.7573L215.731 31.7573L215.731 36.9238C 215.731 37.9776 215.457 38.7481 214.908 39.2396C 214.357 39.7301 213.488 39.9765 212.297 39.9765C 211.337 39.9765 210.603 39.7714 210.098 39.3612C 209.592 38.9477 209.297 38.3183 209.214 37.4719z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M218.196 39.8376L218.196 31.7573L224.19 31.7573L224.19 32.7177L219.298 32.7177L219.298 35.0855L223.844 35.0855L223.844 36.0481L219.298 36.0481L219.298 38.8305L224.336 38.8305L224.336 39.8387L218.196 39.8387L218.196 39.8376z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M225.013 31.7573L226.192 31.7573L228.504 38.7372L231.012 31.7573L232.485 31.7573L234.897 38.7991L237.275 31.7573L238.32 31.7573L235.582 39.8376L234.148 39.8376L231.71 32.7611L229.166 39.8376L227.752 39.8376L225.013 31.7573z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M239.775 39.8376L239.775 31.7573L245.769 31.7573L245.769 32.7177L240.877 32.7177L240.877 35.0855L245.423 35.0855L245.423 36.0481L240.877 36.0481L240.877 38.8305L245.918 38.8305L245.918 39.8387L239.775 39.8387L239.775 39.8376z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M247.665 39.8376L247.665 31.7573L248.801 31.7573L248.801 38.8403L253.413 38.8403L253.413 39.8376L247.665 39.8376z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M254.858 39.8376L254.858 31.7573L255.992 31.7573L255.992 38.8403L260.604 38.8403L260.604 39.8376L254.858 39.8376z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M262.051 39.8376L262.051 31.7573L268.042 31.7573L268.042 32.7177L263.152 32.7177L263.152 35.0855L267.698 35.0855L267.698 36.0481L263.152 36.0481L263.152 38.8305L268.193 38.8305L268.193 39.8387L262.051 39.8387L262.051 39.8376z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M269.94 39.8376L269.94 31.7573L273.545 31.7573C 274.232 31.7573 274.743 31.7876 275.08 31.8484C 275.415 31.9092 275.692 32.0112 275.913 32.1512C 276.18 32.3237 276.384 32.5625 276.529 32.8652C 276.674 33.1669 276.745 33.5174 276.745 33.9168C 276.745 34.4648 276.599 34.8978 276.309 35.2201C 276.019 35.5424 275.571 35.766 274.97 35.8886C 275.399 35.9038 275.708 36.0112 275.907 36.2141C 276.104 36.4182 276.265 36.862 276.394 37.5489L276.394 37.5489L276.598 38.6276C 276.667 38.9694 276.729 39.2321 276.782 39.4111C 276.84 39.5891 276.898 39.7323 276.965 39.8376L276.965 39.8376L275.755 39.8376C 275.704 39.7356 275.655 39.601 275.61 39.4306C 275.568 39.2624 275.521 39.0248 275.472 38.7264L275.472 38.7264L275.25 37.3796C 275.184 36.9705 275.008 36.6851 274.725 36.5245C 274.438 36.3639 273.934 36.2836 273.208 36.2836L273.208 36.2836L271.02 36.2836L271.02 39.8376L269.94 39.8376zM271.019 35.3514L273.127 35.3514C 274.162 35.3514 274.829 35.2537 275.133 35.0573C 275.438 34.862 275.589 34.5039 275.589 33.9808C 275.589 33.5001 275.431 33.1658 275.12 32.9792C 274.806 32.7914 274.141 32.7003 273.117 32.7003L273.117 32.7003L271.018 32.7003L271.018 35.3514L271.019 35.3514z"
            fill={props?.fill || '#000000'}
          />
          <path
            d="M278.246 37.3948L279.436 37.3948C 279.472 37.9396 279.707 38.3596 280.143 38.6547C 280.579 38.9499 281.186 39.0986 281.96 39.0986C 282.727 39.0986 283.334 38.9575 283.779 38.6775C 284.224 38.3954 284.446 38.0166 284.446 37.5359C 284.446 37.3286 284.389 37.1496 284.275 36.9976C 284.161 36.8446 283.982 36.7188 283.741 36.6157C 283.583 36.5538 283.116 36.4637 282.34 36.3476C 282.075 36.3107 281.871 36.2814 281.725 36.2597L281.725 36.2597L281.621 36.2434C 280.606 36.0883 279.918 35.9266 279.555 35.7551C 279.157 35.5543 278.866 35.3102 278.681 35.0226C 278.497 34.7329 278.404 34.3747 278.404 33.9483C 278.404 33.244 278.728 32.6797 279.375 32.2565C 280.022 31.8354 280.896 31.6249 282.005 31.6249C 283.061 31.6249 283.873 31.8235 284.445 32.2228C 285.017 32.6211 285.305 33.1865 285.314 33.9211L285.314 33.9211L284.169 33.9255C 284.125 33.4773 283.909 33.1289 283.523 32.8804C 283.135 32.6341 282.615 32.5115 281.961 32.5115C 281.202 32.5115 280.622 32.6374 280.219 32.8902C 279.819 33.142 279.617 33.5055 279.617 33.9797C 279.617 34.1783 279.659 34.3411 279.746 34.4713C 279.831 34.5994 279.973 34.7122 280.175 34.8077C 280.351 34.8924 280.969 35.0161 282.034 35.181C 282.405 35.2353 282.699 35.282 282.915 35.3189C 283.936 35.4795 284.647 35.7193 285.043 36.0416C 285.439 36.3617 285.639 36.8316 285.639 37.4491C 285.639 38.2445 285.3 38.8663 284.623 39.3091C 283.949 39.7529 282.999 39.9743 281.781 39.9743C 280.658 39.9743 279.788 39.7529 279.174 39.3091C 278.556 38.8663 278.247 38.2424 278.247 37.4382L278.247 37.4382L278.247 37.3948L278.246 37.3948z"
            fill={props?.fill || '#000000'}
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
