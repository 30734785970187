import { FC } from 'react'
import tagSaleMatrix from '@config/tag_sale_text_matrix.json';
import styles from './ProductDiscountMessage.module.css'

interface Props {
  namedTags: any
}

const ProductDiscountMessage: FC<Props> = ({ namedTags }) => {
  let discountTextTag = namedTags?.discount_text;
  
  if(!discountTextTag) {
    for (let [namespace, value] of Object.entries(namedTags)) {
      const tag: string = `${namespace}:${value}`.toLowerCase()
      if(tagSaleMatrix.hasOwnProperty(tag)) {
        discountTextTag = tagSaleMatrix[tag as keyof typeof tagSaleMatrix];
      }
    }
  }

  if(!discountTextTag) return null;

  return (
    <div className={styles.root}>
      {discountTextTag.replace(/_/g, ' ')}
    </div>
  );
}

export default ProductDiscountMessage
