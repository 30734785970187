import { ChevronDown, ChevronUp } from '@components/icons'
import { useUI } from '@components/ui/context'
import Link from '@components/ui/Link'
import cn from 'clsx'
import { FC, useState } from 'react'
import { MegaNavLink } from '@components/common/types'
import ArrowRightSmall from '@components/icons/ArrowRightSmall'

interface props {
  title: string
  link?: string
  links?: MegaNavLink[]
  handleCloseMegaMenu: Function
}

const NavColumnList: FC<props> = ({
  title,
  link,
  links,
  handleCloseMegaMenu,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { closeSidebar } = useUI()

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const closeMenu = () => {
    handleCloseMegaMenu()
    closeSidebar()
  }

  return (
    <>
      <div className="hidden lg:block body-3-bold uppercase text-darkest-grey mb-5">
        {link ? (
          <Link href={link} prefetch={false}>
            {title}
          </Link>
        ) : (
          <span>{title}</span>
        )}
      </div>
      <button
        className="lg:hidden body-1 text-dark-grey w-full flex justify-between focus:outline-none"
        onClick={toggleDrawer}
      >
        <div>{title}</div>
        <div className="lg:hidden mt-2">
          {isDrawerOpen ? <ChevronUp /> : <ChevronDown />}
        </div>
      </button>
      <ul
        className={cn(
          'py-4 lg:py-0 lg:!flex lg:flex-col lg:flex-wrap lg:max-h-[340px]',
          {
            'hidden lg:block': !isDrawerOpen,
          }
        )}
      >
        {links?.map((link) => (
          <li
            key={link.Label}
            className="body-2 text-dark-grey pl-6 lg:pl-0 hover:opacity-75 mb-4"
            onClick={closeMenu}
          >
            <Link href={link.Link} prefetch={false}>
              {link.Label}
            </Link>
          </li>
        ))}
        {link && (
          <li
            className="body-2 text-dark-grey pl-6 lg:pl-0 hover:opacity-75 mb-4"
            onClick={closeMenu}
          >
            <Link className="flex flex-row items-center gap-2" href={link}>
              <span>Shop All</span>
              <ArrowRightSmall />
            </Link>
          </li>
        )}
      </ul>
    </>
  )
}

export default NavColumnList
