import { LeftArrow, LegacyCross } from '@components/icons'
import { Button } from '@components/ui'
import cn from 'clsx'
import Image from "next/legacy/image"
import HelpSupport from '../HelpSupport'
import s from './ServicePortalSidebar.module.css'

const ProfileSidebarView = ({ onClose }: { onClose: () => void }) => {
  const handleClose = () => onClose()

  return (
    <div className={cn(s.root)}>
      <div className={s.container}>
        <div className="sticky top-0 z-10 md:hidden block">
          <header className="py-4 px-6 lg:py-6  flex items-center justify-start bg-accent-0 w-full z-10 border-b border-b-ui-grey-25">
            <div className="text-brand-dark-grey text-body3 font-bold sm:text-label md:text-left text-center flex-1">
              CUSTOMER SERVICE
            </div>
            {handleClose && (
              <button
                onClick={handleClose}
                aria-label="Close"
                className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none ml-auto"
              >
                <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
              </button>
            )}
          </header>
        </div>

        <div className="hidden md:block ">
          <Image
            src="/profile-sidebar-banner.jpg"
            alt="Profile Banner"
            layout="responsive"
            height={207}
            width={368}
            unoptimized
          />
        </div>
        <div className="mt-8 sm:mt-10">
          <HelpSupport
            heading="NEED HELP? PLEASE CONTACT US"
            showFindStoreItem={true}
          />
        </div>

        <div className="px-6 hidden mt-auto mb-4 sm:flex">
          <Button
            className="w-full"
            variant="ghost"
            type="button"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProfileSidebarView
