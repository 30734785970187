import { Action, State, StoreLocation } from './types'
import { slugify } from './utils/slugify'

export const INITIAL_STATE: State = {
  stores: [],
  myStore: null,
  isCurrentLocationFetching: false,
  isFetching: false,
}

function buildTime(hrs: string) {
  const time = hrs.split(':').map((i) => Number(i))
  return new Date(2022, 10, 10, time[0], time[1]).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })
}

function buildOperationTime(normal_hours: StoreLocation['normal_hours']) {
  return normal_hours?.flatMap((normal_hour) => {
    const days = Object.keys(normal_hour)
    return days.map((day) => ({
      day: day,
      time: [
        // @ts-ignore
        buildTime(normal_hour[day].open),
        // @ts-ignore
        buildTime(normal_hour[day].close),
      ].join(' - '),
    }))
  })
}

export const transformStoreLocationData = (store: StoreLocation) => ({
  ...store,
  id: store?.id ?? store?.objectID,
  open_hours: buildOperationTime(store.normal_hours),
  slug: slugify(store.title ?? 'untitled'),
  address: [
    store.address_line_1,
    store.address_line_2,
    store.suburb,
    store.state,
    store.postcode,
  ].join(', '),
})

const reducer = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case 'INIT':
      return Object.assign({}, state, {
        isFetching: true,
      })
    case 'LOAD_STORE':
      return Object.assign({}, state, {
        stores: action.payload
          .map(transformStoreLocationData)
          .filter((s) => s?.lat && s?.lng && s?.location_id)
          .sort((s) => (s?.id === state.myStore?.id ? -1 : 0)),
        isFetching: false,
      })
    case 'REQUEST_SORT_STORE_BY_LOCATION':
      return Object.assign({}, state, {
        isFetching: true,
      })
    case 'REQUEST_SORT_STORE':
      return Object.assign({}, state, {
        isCurrentLocationFetching: true,
      })
    case 'SORT_STORE':
      return Object.assign({}, state, {
        stores: action.payload,
        isCurrentLocationFetching: false,
        isFetching: false,
      })
    case 'SET_MY_STORE':
      const myStore = state.stores.find(
        (store) => store.id === action.payload.id
      )
      if (!myStore) return state
      return Object.assign({}, state, {
        myStore,
        stores: state?.stores.sort((s) => (s.id === myStore.id ? -1 : 0)),
      })
    default:
      return state
  }
}

export default reducer
