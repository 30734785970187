function calculateTotalPrice(entries: any[], tagToFind: string): any {
  let totalPrice = 0

  for (const entry of entries) {
    const variantTags = entry.variant.productTags
    if (variantTags?.includes(tagToFind)) {
      let entryPrice = parseFloat(entry.variant.price)
      if (entry.quantity > 1) {
        entryPrice = parseFloat(entry.variant.price) * parseInt(entry.quantity)
      }

      totalPrice += entryPrice
    }
  }

  return totalPrice
}

export default calculateTotalPrice
