const SearchLight = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'AC71808B-0A94-4B22-980B-F6897E223764'}</title>
      <path
        d="M15.581 15.89l.31-.309a.375.375 0 000-.531l-4.032-4.031a.37.37 0 00-.265-.11h-.322A6.499 6.499 0 006.5 0C2.91.001 0 2.91 0 6.501a6.499 6.499 0 0010.91 4.775v.319c0 .1.04.194.109.265l4.031 4.032a.375.375 0 00.531 0zM6.5 12C3.46 12 1 9.54 1 6.5S3.46 1 6.5 1 12 3.46 12 6.5 9.54 12 6.5 12z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SearchLight
