const SearchBarClose = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.05027 6.34322C6.85501 6.14796 6.53842 6.14796 6.34316 6.34322C6.1479 6.53849 6.1479 6.85507 6.34316 7.05033L11.2929 12.0001L6.34316 16.9498C6.1479 17.1451 6.1479 17.4617 6.34316 17.6569C6.53842 17.8522 6.85501 17.8522 7.05027 17.6569L12 12.7072L16.9498 17.6569C17.145 17.8522 17.4616 17.8522 17.6569 17.6569C17.8521 17.4617 17.8521 17.1451 17.6569 16.9498L12.7071 12.0001L17.6569 7.05033C17.8521 6.85507 17.8521 6.53849 17.6569 6.34322C17.4616 6.14796 17.145 6.14796 16.9498 6.34322L12 11.293L7.05027 6.34322Z" />
    </svg>
  );
};

export default SearchBarClose;
