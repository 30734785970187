import { useCallback } from "react";
import useRecoverCustomer from "@vercel/commerce/customer/use-recover-customer";
import { throwUserErrors, customerRecoverMutation } from "../utils";
export default useRecoverCustomer;
export const handler = {
    fetchOptions: {
        query: customerRecoverMutation
    },
    async fetcher ({ input: { email  } , options , fetch  }) {
        const { customerRecover  } = await fetch({
            ...options,
            variables: {
                email
            }
        });
        throwUserErrors(customerRecover == null ? void 0 : customerRecover.customerUserErrors);
        return null;
    },
    useHook: ({ fetch  })=>()=>{
            return useCallback(async function recoverCustomer(input) {
                const data = await fetch({
                    input
                });
                return data;
            }, [
                fetch
            ]);
        }
};
