import React from 'react'
import { FulfilmentInfoIcon } from '@components/icons'

interface NoticeProps {
  icon?: keyof typeof iconsMap
  content: string | React.ReactNode
}

const iconsMap = {
  fulfilmentInfo: FulfilmentInfoIcon,
}

const Notice = ({ icon, content }: NoticeProps) => {
  const iconComponent = () => {
    if (!icon || !iconsMap[icon]) return null
    const Icon = iconsMap[icon]
    return <Icon />
  }

  if (!content) return null

  return (
    <div className="flex bg-brand-polar-white rounded">
      <div className="border-brand-chrome bg-brand-chrome rounded-l border-2" />
      <div className="p-4 flex">
        <div className="pt-1">{iconComponent()}</div>
        <div className="text-label text-text-subdued pl-3">{content}</div>
      </div>
    </div>
  )
}

export default Notice
