// @ts-nocheck
import cn from 'clsx'
import React, { InputHTMLAttributes, useEffect, useRef } from 'react'
import s from './Input.module.css'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: (...args: any[]) => any
  placeholder?: string
  testLabel?: string
  disabled?: boolean
  label?: string
  value?: string
  className?: string
  focus?: boolean
  width?: string | number
}

const Input: React.FC<InputProps> = (props) => {
  const {
    children,
    onChange,
    placeholder,
    testLabel,
    disabled = false,
    label,
    focus,
    className,
    style = {},
    width,
    type,
    value,
  } = props

  const _input = useRef<any>(null)

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value)
    }
    return null
  }

  useEffect(() => {
    setTimeout(function () {
      focus && _input?.current?.focus()
    }, 200)
  }, [focus])

  return (
    <div className={cn('flex flex-col', className)}>
      {label && <label className={s.label}>{label}</label>}
      <input
        ref={_input}
        style={{
          width,
          ...style,
        }}
        className={s.root}
        onChange={handleOnChange}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        placeholder={placeholder}
        data-testid={testLabel}
        disabled={disabled}
        type={type}
        value={value}
      />
    </div>
  )
}

export default Input
