import { MobileNav, Navigation, UserNav } from '@components/common'
import { LogoLg } from '@components/icons'
import { Container } from '@components/ui'
import { useUI } from '@components/ui/context'
import { groupNavItems } from '@lib/helpers/group-nav-items'
import cn from 'clsx'
import throttle from 'lodash.throttle'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import MegaMenu from '../MegaMenu'
import NavbarRoot from './NavbarRoot'
import TopMenu from "@components/common/TopMenu";
import AnnouncementBar from './AnnouncementBar'

interface Link {
  href: string
  label: string
}

interface NavbarProps {
  links?: Link[]
  navItems: any
  announcement?: Partial<{ title: string, description: string }>
}

const Navbar: FC<NavbarProps> = ({ announcement, navItems }) => {
  const { asPath: path } = useRouter()
  const groupedNavItems: any[] = groupNavItems(navItems?.data?.slices)
  const {
    toggleSidebar,
    closeSidebarIfPresent,
    openModal,
    setSidebarView,
    displayCartNotification,
  } = useUI()

  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const [megaMenuIndex, setMegaMenuIndex] = useState(-1)
  const [hasScrolled, setHasScrolled] = useState(false)
  const [iconsColour, setIconsColour] = useState('#272727')


  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0
      const { scrollTop } = document.documentElement
      const scrolled = scrollTop > offset

      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled)
      }
    }, 200)

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [hasScrolled])


  const hideMegaMenu = () => {
    setShowMegaMenu(false)
    setMegaMenuIndex(-1)
  }

  const setMegaMenuVisibility = (value: boolean) => {
    setShowMegaMenu(value)
  }

  const setMegaMenuIndexHandler = (index: number) => {
    setMegaMenuIndex(index)
  }

  return <>
    <TopMenu />
    <NavbarRoot>
      <div
        className='bg-white lg:hover:text-ui-dark-grey border-b border-ui-light-grey'
      >
        <Container className="relative">
          <div className="flex justify-between max-h-14 lg:max-h-20">
            <button
              type="button"
              onClick={() => {
                setSidebarView('MOBILE_MENU_VIEW')
                toggleSidebar()
              }}
              className="focus:outline-none flex items-center lg:hidden py-4 pr-5"
            >
              <MobileNav iconsColour={iconsColour} />
            </button>
            <div className="navItem py-4 lg:py-6 lg:pr-7">
              <Link href="/" legacyBehavior>
                <a className="border-0" aria-label="Logo">
                  <div className="visible lg:hidden">
                    <LogoLg fill={iconsColour} />
                  </div>
                  <div className="hidden lg:block">
                    <LogoLg fill={iconsColour} />
                  </div>
                </a>
              </Link>
            </div>

            <div className="navItem hidden flex-1 lg:block">
              <Navigation
                menuItems={groupedNavItems}
                handleMegaMenuVisibility={setMegaMenuVisibility}
                handleMegaMenuIndex={setMegaMenuIndexHandler}
                currentVisibility={showMegaMenu}
                currentIndex={megaMenuIndex}
                linkColor={iconsColour}
              />
            </div>
            <div className="flex items-center justify-end flex-1 space-x-8">
              <UserNav />
            </div>
          </div>
          {/* {displayCartNotification && <CartNotification />} */}
        </Container>
      </div>
      <div
        className={cn('absolute z-10 w-full mega-nav-transition', {
          'mega-nav-transition-show':
            showMegaMenu && groupedNavItems[megaMenuIndex]?.type === 'MegaNav',
        })}
      >
        <div className="bg-overlay-dark h-screen bg-opacity-40">
          <div onMouseLeave={hideMegaMenu}>
            <MegaMenu
              handleMegaMenuVisibility={setMegaMenuVisibility}
              menuItems={groupedNavItems[megaMenuIndex]?.items}
            />
          </div>
        </div>

      </div>
      <style jsx>
        {`
          .mega-nav-transition {
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            opacity: 0;
          }
          .mega-nav-transition-show {
            visibility: visible;
            opacity: 100;
          }
          @media (min-width: 1024px) {
            .navItem {
              display: flex;
              justify-content: center;
            }
            .navItem:first-child > span {
              margin-right: auto;
            }
            .navItem:last-child > span {
              margin-left: auto;
            }
          }
        `}
      </style>
    </NavbarRoot>
    {announcement && <AnnouncementBar {...announcement} />}
  </>;
}

export default Navbar
