const ArrowRightWhite = ({ fill = '#fff', ...props }) => {
  return (
    <svg
      width={17}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m8.694.16-.253.254a.429.429 0 0 0 0 .607l6.373 6.37H1.393a.43.43 0 0 0-.429.428v.357a.43.43 0 0 0 .429.429h13.421l-6.373 6.373a.429.429 0 0 0 0 .607l.253.254c.168.167.44.167.607 0l7.537-7.534a.429.429 0 0 0 0-.607L9.302.164a.426.426 0 0 0-.607-.003Z"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowRightWhite
