import calculateTotalPrice from '@lib/calculateTotalPriceFromTag'

type foundPromoType = {
  collectionTag: string
  productType: 'percent' | 'free' | 'priceoff'
  pecentageOff?: number
  priceOff?: number
  minSpend?: {
    currentSpend: number
    minSpendValue: number
  }
  promoHandle: string
  promoRemovePrice: number
  lineItem: any
}

export const promoFinder = (cartItems: any, promos: any) => {
  let listOfCollections: any[] = []
  let listOfDiscountedProduct: any[] = []
  let foundCollection = new Set()
  let promoList: foundPromoType[] = []

  //gets a list of collections to check against
  promos.map((promo: { collectionTag: any; freeorDiscountedProduct: any }) => {
    listOfCollections.push(promo.collectionTag)
    listOfDiscountedProduct.push(promo.freeorDiscountedProduct)
  })

  //checks the cartItems against the list of collections
  if (cartItems) {
    cartItems.map(
      (cartItem: { path: any; variant: { productTags: any[] } }) => {
        if (!listOfDiscountedProduct.includes(cartItem.path)) {
          cartItem.variant.productTags.forEach((tag: any) => {
            if (listOfCollections.includes(tag)) {
              foundCollection.add(tag)
            }
          })
        }
      }
    )
  }

  //returns the full promo if found within the above
  const foundPromos = promos.filter((promo: { collectionTag: string }) =>
    foundCollection.has(promo.collectionTag)
  )

  foundPromos.map(
    (promo: {
      collectionTag: string
      freeItem: any
      freeProductDiscountPercentage: number
      minSpend: any
      freeProductDiscountedPrice: any
      freeorDiscountedProduct: any
      freeLineItem: any
    }) => {
      const currentSpendAmount: number = calculateTotalPrice(
        cartItems,
        promo.collectionTag
      )

      let productType: 'percent' | 'free' | 'priceoff'

      if (promo.freeItem) {
        productType = 'free'
      } else if (promo.freeProductDiscountPercentage > 0) {
        productType = 'percent'
      } else {
        productType = 'priceoff'
      }

      let removePrice: number

      if (productType === 'percent') {
        removePrice =
          (promo.freeLineItem?.product.price.value / 100) *
          promo.freeProductDiscountPercentage
      } else if (productType === 'priceoff') {
        removePrice = promo.freeProductDiscountedPrice
      } else {
        removePrice = promo.freeLineItem?.product.price.value
      }

      if (promo.minSpend) {
        if (promo.minSpend <= currentSpendAmount) {
          promoList.push({
            collectionTag: promo.collectionTag,
            productType: productType,
            minSpend: {
              currentSpend: currentSpendAmount,
              minSpendValue: promo.minSpend,
            },
            promoHandle: promo.freeorDiscountedProduct,
            promoRemovePrice: removePrice,
            lineItem: promo.freeLineItem,
          })
        } else {
          return
        }
      }

      if (!promo.minSpend)
        promoList.push({
          collectionTag: promo.collectionTag,
          productType: productType,
          pecentageOff: promo.freeProductDiscountPercentage,
          priceOff: promo.freeProductDiscountedPrice,
          promoHandle: promo.freeorDiscountedProduct,
          promoRemovePrice: removePrice,
          lineItem: promo.freeLineItem,
        })
    }
  )
  return promoList
}
