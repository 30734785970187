import { useCallback } from "react";
import useResetCustomer from "@vercel/commerce/customer/use-reset-customer";
import { throwUserErrors, customerResetMutation } from "../utils";
import { CommerceError } from "@vercel/commerce/utils/errors";
export default useResetCustomer;
export const handler = {
    fetchOptions: {
        query: customerResetMutation
    },
    async fetcher ({ input: { input , id  } , options , fetch  }) {
        const { resetToken , password  } = input;
        if (!(resetToken && password)) {
            throw new CommerceError({
                message: "A password is required to reset."
            });
        }
        const { customerReset  } = await fetch({
            ...options,
            variables: {
                id,
                input
            }
        });
        throwUserErrors(customerReset == null ? void 0 : customerReset.customerUserErrors);
        return null;
    },
    useHook: ({ fetch  })=>()=>{
            return useCallback(async function reset(input) {
                const data = await fetch({
                    input
                });
                return data;
            }, [
                fetch
            ]);
        }
};
