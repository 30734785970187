import { SearchHits, SearchState } from '@components/algolia'
import { Cross, SearchLight } from '@components/icons'
import { getDefaultSearchString, SearchClient } from '@lib/algolia'
import cn from 'clsx'
import { useRouter } from 'next/router'
import { FC, memo, useEffect, useState } from 'react'
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom'
import s from './Searchbar.module.css'

interface Props {
  onClose: () => void
}

const Searchbar: FC<Props> = ({ onClose }) => {
  const router = useRouter()
  const [searchValue, setSearchValue] = useState<string>('')

  const algoliaIndex = `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_PRIMARY_INDEX}_recently_ordered_count_desc`

  useEffect(() => {
    router.prefetch('/search')
  }, [router])

  useEffect(() => {
    const timer = setTimeout(() => {
      document.getElementById('search-bar-input')?.focus()
    }, 100)
    return () => clearTimeout(timer)
  }, [])

  const handleSearchValue = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  const handleReset = () => {
    setSearchValue('')
  }

  const goToSearchPage = () => {
    handleReset()
    router.push(`/search?q=${searchValue}`)
    onClose()
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    goToSearchPage()
  }

  const isSearched = searchValue && searchValue.length

  const customSearchClient = {
    search(requests: any) {
      if (requests.every(({ params }: { params: any }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        })
      }
      return SearchClient.search(requests)
    },
  }

  return (
    <>
      {/* <div className={cn(s.root)}> */}
      <div className={cn(s.root)}>
        {/* <InstantSearch indexName={algoliaIndex} searchClient={SearchClient}> */}
        <InstantSearch
          indexName={algoliaIndex}
          searchClient={customSearchClient}
          stalledSearchDelay={400}
        >
          <div
            className={cn('fixed w-full bg-white z-20', {
              'search-bar-outer-shadow ': isSearched,
            })}
          >
            <div
              className={cn(
                s.container,
                s.searchForm,
                'flex gap-4 items-center body-1 '
              )}
            >
              <Configure hitsPerPage={16} filters={getDefaultSearchString()} />
              <SearchBox
                onSubmit={handleSubmit}
                onChange={handleSearchValue}
                translations={{
                  placeholder: 'Search for a colour or product...',
                }}
                onReset={handleReset}
                reset={
                  <div className="underline label text-brand-dark-grey">
                    Clear
                  </div>
                }
                submit={
                  <>
                    <SearchLight />
                  </>
                }
                inputId="search-bar-input"
                autoFocus
              />

              <button type="button" onClick={onClose}>
                <Cross className="cursor-pointer" />
              </button>
            </div>
          </div>

          {isSearched && (
            <div className="bg-brand-light-grey pt-12 md:pt-16 h-screen">
              <SearchHits onClose={onClose} />

              <SearchState goToSearchPage={goToSearchPage} />
            </div>
          )}
        </InstantSearch>
      </div>
      <style jsx>
        {`
          .search-bar-outer-shadow {
            box-shadow: 0 2px 4px rgba(100, 100, 100, 0.1);
          }
        `}
      </style>
      <style global jsx>
        {`
          .ais-SearchBox {
            width: 100%;
          }
          .ais-SearchBox-form {
            display: flex;
            gap: 1rem;
          }
          .ais-SearchBox-form button[type='submit'] {
            order: 0;
          }
          .ais-SearchBox-form input[type='search'] {
            order: 1;
            width: 100%;
            padding-left: 3px;
            padding-top: 20px;
            padding-bottom: 20px;
            outline: none;
          }

          #search-bar-input::-webkit-search-decoration,
          #search-bar-input::-webkit-search-cancel-button,
          #search-bar-input::-webkit-search-results-button,
          #search-bar-input::-webkit-search-results-decoration {
            -webkit-appearance: none;
          }

          .ais-SearchBox-form button[type='reset'] {
            order: 2;
          }
          .ais-SearchBox-input {
            background: white;
          }

          @media (max-width: 767px) {
            .ais-SearchBox-form input[type='search'] {
              padding-top: 12px;
              padding-bottom: 12px;
            }
          }
        `}
      </style>
    </>
  )
}

export default memo(Searchbar)
