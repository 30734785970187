const Cross = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.05033 6.34315C6.85507 6.14789 6.53849 6.14789 6.34322 6.34315C6.14796 6.53841 6.14796 6.85499 6.34322 7.05025L11.293 12L6.34322 16.9497C6.14796 17.145 6.14796 17.4616 6.34322 17.6569C6.53849 17.8521 6.85507 17.8521 7.05033 17.6569L12.0001 12.7071L16.9498 17.6569C17.1451 17.8521 17.4617 17.8521 17.6569 17.6569C17.8522 17.4616 17.8522 17.145 17.6569 16.9497L12.7072 12L17.6569 7.05025C17.8522 6.85499 17.8522 6.53841 17.6569 6.34315C17.4617 6.14789 17.1451 6.14789 16.9498 6.34315L12.0001 11.2929L7.05033 6.34315Z"
        fill="#646464"
      />
    </svg>
  )
}

export default Cross
