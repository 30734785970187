import {
  ForgotSidebarView,
  LoginSidebarView,
  SignUpSidebarView,
} from '@components/auth'
import { Bag, LeftArrow, LegacyCross, Logout, User } from '@components/icons'
import { Button, Input } from '@components/ui'
import useLogout from '@framework/auth/use-logout'
import useCustomer from '@framework/customer/use-customer'
import cn from 'clsx'
import Image from "next/legacy/image"
import { useState } from 'react'
import HelpSupport from '../HelpSupport'
import ProfileSidebarItem from '../ProfileSidebarItem'
import s from './ProfileSidebarView.module.css'

const ProfileSidebarView = ({ onClose }: { onClose: () => void }) => {
  const { data: isCustomerLoggedIn } = useCustomer()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [showLoginView, setShowLoginView] = useState(false)
  const [showSignupView, setShowSignupView] = useState(false)
  const [showForgotView, setShowForgotView] = useState(false)
  const logout = useLogout()
  const handleClose = () => onClose()
  const handleLogout = () => {
    onClose()
    logout()
  }

  const handleEmail = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    if (email == '') return

    try {
      setLoading(true)
      const response = await fetch('/api/customer/check', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      const result = await response.json()
      if (result) {
        setShowLoginView(true)
      } else {
        setShowSignupView(true)
      }
      setLoading(false)
    } catch (e: any) {
      console.log(e)
      setLoading(false)
    }
  }
  return (
    <div className={cn(s.root)}>
      <div className={s.container}>
        <div
          className={cn(
            'sticky top-0 z-10 block',
            !isCustomerLoggedIn && 'sm:hidden'
          )}
        >
          <header className="py-4 px-6 lg:py-6  flex items-center justify-start bg-accent-0 w-full z-10 border-b border-b-ui-grey-25">
            {handleClose && (
              <button
                onClick={handleClose}
                aria-label="Close"
                className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none  block sm:hidden"
              >
                <LeftArrow className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
              </button>
            )}
            <div className="text-brand-dark-grey text-body3 font-bold sm:text-label sm:text-left text-center flex-1">
              {isCustomerLoggedIn ? 'YOUR PROFILE' : 'PROFILE'}
            </div>
            {handleClose && (
              <button
                onClick={handleClose}
                aria-label="Close"
                className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none ml-auto"
              >
                <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
              </button>
            )}
          </header>
        </div>
        {isCustomerLoggedIn ? (
          <>
            <div className="px-6 mt-4 flex flex-col mb-10">
              <ProfileSidebarItem
                icon={User}
                title="Profile overview"
                link="/me"
              />
              <ProfileSidebarItem icon={Bag} title="Orders" link="/me/orders" />
              <ProfileSidebarItem
                icon={Logout}
                title="Log out"
                onPress={handleLogout}
              />
            </div>
          </>
        ) : (
          <>
            <div className="hidden sm:block">
              <Image
                src="/profile-sidebar-banner.jpg"
                alt="Profile Banner"
                layout="responsive"
                height={207}
                width={368}
                unoptimized
              />
            </div>
            <form
              onSubmit={handleEmail}
              className="flex flex-col mx-6 pb-10 border-b border-b-ui-grey-25 mb-10"
            >
              <p className="text-sm font-normal mt-8 mb-4">
                Enter your email to get started
              </p>
              <Input
                type="email"
                placeholder="Email address"
                onChange={setEmail}
                focus={true}
              />

              <Button
                className="mt-6"
                variant="slim"
                type="submit"
                loading={loading}
              >
                Get started
              </Button>
            </form>
          </>
        )}
        <HelpSupport />
        {!isCustomerLoggedIn && (
          <div className="px-6 hidden mt-auto mb-4 sm:flex">
            <Button
              className="w-full"
              variant="ghost"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        )}
      </div>
      <LoginSidebarView
        defaultEmail={email}
        openSignupSidebar={() => {
          setShowSignupView(true)
        }}
        openForgotSidebar={() => {
          setShowForgotView(true)
        }}
        open={showLoginView}
        onClose={() => {
          setShowLoginView(false)
        }}
      />
      <ForgotSidebarView
        defaultEmail={email}
        openLoginSidebar={() => {
          setShowLoginView(true)
        }}
        open={showForgotView}
        onClose={() => {
          setShowForgotView(false)
        }}
      />
      <SignUpSidebarView
        defaultEmail={email}
        openLoginSidebar={() => {
          setShowLoginView(true)
        }}
        open={showSignupView}
        onClose={() => {
          setShowSignupView(false)
        }}
      />
    </div>
  )
}

export default ProfileSidebarView
