import _ from 'lodash'

import { LatLng, StoreItem, StoreLocation } from '../types'
import { canUseDOM } from '../utils/dom'

const sortedByDistanceMatrix = async (
  stores: StoreItem[] | StoreLocation[],
  currentPosition: LatLng
) => {
  if (!canUseDOM) return

  try {
    if (!currentPosition) return

    const origin = new google.maps.LatLng(
      currentPosition?.lat,
      currentPosition?.lng
    )

    const destinations = stores.map(
      (store) =>
        new google.maps.LatLng(
          (store as StoreItem)?.location?.lat ?? (store as StoreLocation)?.lat ?? 0,
          (store as StoreItem)?.location?.lng ?? (store as StoreLocation)?.lng ?? 0
        )
    )

    const chunks = _.chunk(destinations, 25)

    var service = new google.maps.DistanceMatrixService()

    const results = await Promise.all(
      chunks.map((chunk) =>
        service.getDistanceMatrix({
          origins: [origin],
          destinations: chunk,
          // @ts-ignore
          travelMode: 'DRIVING',
        })
      )
    )

    const allResults = _.flatten(results.map((r) => r.rows[0].elements))

    return _.sortBy(
      stores.map((store, index) =>
        Object.assign({}, store, {
          distance: allResults[index]?.distance?.value,
        })
      ),
      ['distance']
    )
  } catch (error) {
    console.error('sortedByDistanceMatrix', error)
  }
}

export default sortedByDistanceMatrix
