import { useCallback } from "react";
import useActivateCustomer from "@vercel/commerce/customer/use-activate-customer";
import { throwUserErrors, customerActivateMutation } from "../utils";
import { CommerceError } from "@vercel/commerce/utils/errors";
export default useActivateCustomer;
export const handler = {
    fetchOptions: {
        query: customerActivateMutation
    },
    async fetcher ({ input: { input , id  } , options , fetch  }) {
        const { activationToken , password  } = input;
        if (!(activationToken && password)) {
            throw new CommerceError({
                message: "A password is required to reset."
            });
        }
        const { customerActivate  } = await fetch({
            ...options,
            variables: {
                id,
                input
            }
        });
        throwUserErrors(customerActivate == null ? void 0 : customerActivate.customerUserErrors);
        return null;
    },
    useHook: ({ fetch  })=>()=>{
            return useCallback(async function activate(input) {
                const data = await fetch({
                    input
                });
                return data;
            }, [
                fetch
            ]);
        }
};
