import useAddItem from "@vercel/commerce/cart/use-add-item";
import { CommerceError } from "@vercel/commerce/utils/errors";
import Cookies from "js-cookie";
import { useCallback } from "react";
import useCart from "./use-cart";
import { SHOPIFY_CHECKOUT_ID_COOKIE, SHOPIFY_CHECKOUT_URL_COOKIE } from "../const";
import { checkoutCreate, checkoutLineItemAddMutation, checkoutToCart, getCheckoutId } from "../utils";
export default useAddItem;
export const handler = {
    fetchOptions: {
        query: checkoutLineItemAddMutation
    },
    async fetcher ({ input: itemOrItems , options , fetch  }) {
        let lineItems = [];
        if (Array.isArray(itemOrItems)) {
            lineItems = itemOrItems.map((item)=>{
                if (item.quantity && (!Number.isInteger(item.quantity) || item.quantity < 1)) {
                    throw new CommerceError({
                        message: "The item quantity has to be a valid integer greater than 0"
                    });
                }
                return {
                    variantId: item.variantId,
                    quantity: item.quantity ?? 1
                };
            });
        } else {
            const item = itemOrItems;
            if (item.quantity && (!Number.isInteger(item.quantity) || item.quantity < 1)) {
                throw new CommerceError({
                    message: "The item quantity has to be a valid integer greater than 0"
                });
            }
            lineItems = [
                {
                    variantId: item.variantId,
                    quantity: item.quantity ?? 1
                }, 
            ];
        }
        let checkoutId = getCheckoutId();
        if (!checkoutId) {
            return checkoutToCart(await checkoutCreate(fetch, lineItems));
        } else {
            try {
                const { checkoutLineItemsAdd  } = await fetch({
                    ...options,
                    variables: {
                        checkoutId,
                        lineItems
                    }
                });
                return checkoutToCart(checkoutLineItemsAdd);
            } catch (error) {
                if (error instanceof Error) {
                    console.log("Checkout error", error, typeof error, error == null ? void 0 : error.message);
                    if ((error == null ? void 0 : error.message) === "Checkout does not exist" || (error == null ? void 0 : error.message) === "Unable to access Checkout.") {
                        console.log("Will remove checkout cookie...");
                        Cookies.remove(SHOPIFY_CHECKOUT_ID_COOKIE);
                        Cookies.remove(SHOPIFY_CHECKOUT_URL_COOKIE);
                    }
                }
            }
        }
    },
    useHook: ({ fetch  })=>()=>{
            const { mutate  } = useCart();
            return useCallback(async function addItem(input) {
                const data = await fetch({
                    input
                });
                await mutate(data, false);
                return data;
            }, [
                fetch,
                mutate
            ]);
        }
};
