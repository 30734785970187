import React from 'react'
import Script from 'next/script'

const Scripts = () => {
  return (
    <>
      <Script
        id="hotjar"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2257502,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.defer=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
        }}
      />
      <Script
        id="klaviyo"
        strategy="afterInteractive"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VPYdS6`}
      />
    </>
  )
}

export default Scripts
