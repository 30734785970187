import { Close } from '@components/icons'
import { FC, useState } from 'react'
import cn from 'clsx'

interface Props {
  title: string
  description: string
}

const AnnouncementBar: FC<Partial<Props>> = ({ title, description}) => {
  const [isShow, handleClose] = useState(true)

  return (
    <div className={cn("bg-brand-chrome h-10 items-center px-4 lg:px-0", {
      flex: isShow,
      hidden: !isShow
    })}>
      <div className="py-1.5 relative wrapper mx-auto">
        <div className="flex justify-center">
          <span className="body-2-bold text-white pr-2">
            {title}
          </span>
          <span className="text-white body-2 md:pt-0">
            {description}
          </span>
        </div>
        <button
          className="absolute h-full top-0 right-0 lg:right-16 cursor-pointer bg-transparent border-0 text-white"
          onClick={() => handleClose(false)}
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.05033 0.343148C0.855069 0.147885 0.538486 0.147886 0.343224 0.343148C0.147962 0.53841 0.147962 0.854992 0.343224 1.05025L5.29297 6L0.343224 10.9497C0.147962 11.145 0.147962 11.4616 0.343224 11.6569C0.538486 11.8521 0.855069 11.8521 1.05033 11.6569L6.00008 6.70711L10.9498 11.6569C11.1451 11.8521 11.4617 11.8521 11.6569 11.6569C11.8522 11.4616 11.8522 11.145 11.6569 10.9497L6.70718 6L11.6569 1.05025C11.8522 0.854992 11.8522 0.53841 11.6569 0.343148C11.4617 0.147886 11.1451 0.147886 10.9498 0.343148L6.00008 5.29289L1.05033 0.343148Z" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default AnnouncementBar
