/* eslint-disable react-hooks/exhaustive-deps */
import Link from '@components/ui/Link'
import cn from 'clsx'
import { FC, MouseEvent, useState } from 'react'

interface Props {
  children?: any
  menuItems?: any[] | undefined
  transparentHeader?: boolean
  handleMegaMenuVisibility: (visible: boolean) => void
  handleMegaMenuIndex: (index: number) => void
  currentVisibility: boolean
  currentIndex: number
  linkColor: string
}

const Navigation: FC<Props> = ({
  menuItems,
  transparentHeader,
  handleMegaMenuIndex,
  handleMegaMenuVisibility,
  currentIndex,
  linkColor,
}) => {
  const [navHovered, setNavHovered] = useState(false)

  return (
    <>
      <ul
        className={cn('flex h-full label', {
          'text-white': linkColor === 'white',
          'text-ui-darkest-grey': linkColor === '#272727',
        })}
      >
        {menuItems?.map((nav, index) => {
          const setMegaNav = () => {
            if (nav?.items?.length) {
              handleMegaMenuVisibility(true)
              handleMegaMenuIndex(index)
            }
            setNavHovered(true)
          }

          const resetMegaNav = (e: MouseEvent) => {
            if (nav.link) setNavHovered(false)
            if (e.clientY < 70) {
              handleMegaMenuIndex(-1)
              handleMegaMenuVisibility(false)
              setNavHovered(false)
            }
          }

          const isSale = nav.label.toUpperCase() === 'SALE'

          return (
            <li
              key={nav.label}
              className={cn('px-3 relative whitespace-nowrap', {
                'text-text-sale': isSale,
              })}
              onMouseOver={setMegaNav}
              onMouseLeave={resetMegaNav}
            >
              <div
                className={cn(
                  'mt-0.5 flex cursor-pointer border-transparent border-b-2 hover:border-b-2',
                  {
                    'hover:border-ui-dark-grey': !isSale,
                    'hover:border-ui-sale': isSale,
                    'border-b-2 border-ui-dark-grey':
                      currentIndex === index && navHovered,
                    'border-b-2 border-ui-sale hover:border-ui-sale':
                      currentIndex === index && navHovered && isSale,
                  }
                )}
              >
                {nav?.link ? (
                  <Link className="py-6" href={nav.link}>
                    {nav.label}
                  </Link>
                ) : (
                  <div className="py-6">{nav.label}</div>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default Navigation
