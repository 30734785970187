import { UserNav } from '@components/common'
import { ChevronLeft, LegacyCross, LogoLg } from '@components/icons'
import { useUI } from '@components/ui'
import processLocale, { LOCALES_MAP } from '@lib/locale'
import cn from 'clsx'
import { useRouter } from 'next/router'
import { FC, ReactNode } from 'react'
// import CountrySelectorButton from '../CountrySelectorButton'
import s from './SidebarLayout.module.css'
import type { LineItem } from '@commerce/types/cart'
import useCart from '@framework/cart/use-cart'

const countItem = (count: number, item: LineItem) => count + item.quantity

type ComponentProps = {
  className?: string
  itemsCount?: Number
  children: ReactNode
} & (
  | { handleClose: () => any; handleBack?: never }
  | { handleBack: () => any; handleClose?: never }
)

const SidebarLayout: FC<ComponentProps> = ({
  children,
  className,
  handleBack,
  handleClose,
}) => {
  const { sidebarView } = useUI()
  const { locale } = useRouter()
  const currentLocale = processLocale(locale)
  const { data } = useCart()

  const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0

  return (
    <div className={cn(s.root, className)}>
      {sidebarView === 'CART_VIEW' && (
        <div className="sticky top-0 z-10">
          <header className="py-6 px-6 flex items-center justify-start bg-accent-0 w-full z-10 border-b border-b-ui-grey-25 bg-white">
            <div className="text-brand-dark-grey font-bold text-label">
              YOUR CART
            </div>
            <div className="text-brand-dark-grey ml-2 font-normal text-label">
              {itemsCount} {itemsCount == 1 ? 'item' : 'items'}
            </div>
            {handleClose && (
              <button
                onClick={handleClose}
                aria-label="Close"
                className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none ml-auto"
              >
                <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
              </button>
            )}
          </header>
          {/* <header className="flex items-center justify-start px-6 py-4 text-brand-dark-grey bg-accent-0 w-full z-10 border-b border-b-ui-grey-25">
            <div className="text-body2 font-light">Shipping to:</div>
            <div className=" text-body2 font-medium ml-2">
              {LOCALES_MAP[currentLocale.toLowerCase()]?.name}
            </div>
          </header> */}
        </div>
      )}
      {sidebarView === 'MOBILE_MENU_VIEW' && (
        <header className={s.header}>
          {handleClose && (
            <button
              onClick={handleClose}
              aria-label="Close"
              className="hover:text-accent-5 transition ease-in-out duration-150 flex items-center focus:outline-none mr-6"
            >
              <LegacyCross className="hover:text-accent-3" />
              <LogoLg className="ml-3" />
            </button>
          )}
          {handleBack && (
            <button
              onClick={handleBack}
              aria-label="Go back"
              className="hover:text-accent-5 transition ease-in-out duration-150 flex items-center focus:outline-none"
            >
              <ChevronLeft className="h-6 w-6 hover:text-accent-3" />
              <span className="ml-2 text-accent-7 text-xs">Back</span>
            </button>
          )}
          <UserNav />
        </header>
      )}

      <div className={s.container}>{children}</div>
    </div>
  )
}

export default SidebarLayout
