import { useCallback } from "react";
import { checkoutToCart, checkoutAttributesMutation, getCheckoutId } from "../utils";
import Cookies from "js-cookie";
import useUpdateCart from "@vercel/commerce/cart/use-update-cart";
import { SHOPIFY_CHECKOUT_ID_COOKIE, SHOPIFY_CHECKOUT_URL_COOKIE } from "../const";
import useCart from "./use-cart";
export default useUpdateCart;
export const handler = {
    fetchOptions: {
        query: checkoutAttributesMutation
    },
    async fetcher ({ input: attributes , options , fetch  }) {
        let checkoutId = getCheckoutId();
        if (checkoutId) {
            const { checkoutAttributesUpdateV2: { checkout  } ,  } = await fetch({
                ...options,
                variables: {
                    checkoutId,
                    input: attributes
                }
            });
            if (checkout == null ? void 0 : checkout.completedAt) {
                Cookies.remove(SHOPIFY_CHECKOUT_ID_COOKIE);
                Cookies.remove(SHOPIFY_CHECKOUT_URL_COOKIE);
                return null;
            } else {
                return checkoutToCart({
                    checkout
                });
            }
        }
        return null;
    },
    useHook: ({ fetch  })=>()=>{
            const { mutate  } = useCart();
            return useCallback(async function updateCart(input) {
                const data = await fetch({
                    input
                });
                await mutate(data, false);
                return data;
            }, [
                fetch,
                mutate
            ]);
        }
};
