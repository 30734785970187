import { LineItem } from '@commerce/types/cart'

function getItemsWithHiddenTag(cartItems: LineItem[]): LineItem[] {
  return cartItems.filter(
    (item) =>
      item.variant &&
      item.variant.productTags &&
      item.variant.productTags.includes('cart:hidden')
  )
}

export default getItemsWithHiddenTag
