import { ChevronRight, Cross } from '@components/icons'
import cn from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
interface Props {
  onClose: () => void
  title?: string
}

const MobileProfileSidebar = ({ onClose, title }: Props) => {
  const sidebarLinks: { label: string; link: string }[] = [
    { label: 'Your details', link: '/me' },
    { label: 'Orders', link: '/me/orders' },
  ]
  const Router = useRouter()
  const handleLinkSelect = (index: number) => {
    Router.push(sidebarLinks[index].link)
    onClose()
  }
  const { asPath } = useRouter()
  return <>
    <div className="h-auto pt-10 pb-4 px-6 ">
      <div
        onClick={onClose}
        className="md:hidden right-3 top-3 absolute p-2 text-brand-dark-grey"
      >
        <Cross />
      </div>
      <div className="label-bold uppercase text-center pb-8">{title}</div>
      <div className="body-1 text-center">
        {sidebarLinks.map((link, index) => {
          const selectLink = () => handleLinkSelect(index)
          return (
            <div key={index} onClick={selectLink}>
              <Link
                href={link?.link || '/'}
                passHref
                className={cn(
                  'flex justify-between items-center body-1 text-brand-dark-grey pb-6',
                  {
                    ' body-1-bold text-brand-dark-grey':
                      asPath === link?.link,
                  }
                )}>

                {link?.label}
                <ChevronRight />

              </Link>
            </div>
          );
        })}
      </div>
    </div>
  </>;
}
export default MobileProfileSidebar
