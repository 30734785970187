import Image from 'next/legacy/image'
import Link from 'next/link'
import { FC } from 'react'
import { getAlgoliaUserToken, searchInsights } from '@lib/algolia'
import cn from 'clsx'
import PlaceholderImg from '../../../../site/public/product-img-placeholder.png'
import ProductDiscountMessage from '@components/product/ProductDiscountMessage'
import { useTagPromoOff } from '@lib/TagPromoOffProvider'
import ArrowRight from '../../icons/ArrowRight'

interface props {
  id: string
  name: string
  handle?: string
  description?: string
  price: any
  compareAtPrice: any
  images: {
    url?: string
    src?: string
  }[]
  link?: string
  reviews?: string
  stars?: string
  colors?: {
    key: string
    value: string
  }[]
  brand: string
  namedTags: any
  isSoldOut?: boolean
  tags: string[]
}

const ProductCard: FC<props> = ({
  name,
  price,
  compareAtPrice,
  images,
  link = '/',
  isSoldOut,
  colors,
  brand,
  id,
  namedTags,
  tags,
}) => {
  // InsightsSearchViewObjectIDsEvent
  const handleClick = () => {
    const userToken = getAlgoliaUserToken()
    searchInsights('clickedObjectIDs', {
      eventName: 'Clicked product card',
      index: 'shopify_products',
      objectIDs: [id],
      userToken,
    })
  }

  const namedTagsEntries = Object.entries(namedTags ? namedTags : {})

  let namedTagsArr: string[] = []
  namedTagsEntries.map((entry) => {
    namedTagsArr.push(`${entry[0]}:${entry[1]}`)
  })

  const combinedTagsArr = namedTagsArr.concat(tags)

  const priceFormatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  })

  const { withTagPromoOff } = useTagPromoOff()

  const {
    price: newPrice,
    compare_at_price,
    isPromoOff,
    isOnSale,
  } = withTagPromoOff(combinedTagsArr, price, compareAtPrice)

  const isPremium = combinedTagsArr
    .map((tag) => tag.toLowerCase())
    .includes('premium:yes')

  const DisplayPrice = () => {
    if (isPremium)
      return (
        <span>
          {priceFormatter.format(
            Math.max(newPrice || 0, compare_at_price || 0)
          )}
        </span>
      )

    return (
      <>
        <span
          className={cn({
            'text-text-sale pr-1': isOnSale && !isPromoOff,
            'text-promo-green pr-1': isPromoOff,
          })}
        >
          {priceFormatter.format(newPrice)}
        </span>
        {isOnSale && (
          <span className="line-through text-text-disabled">
            &nbsp;{priceFormatter.format(compare_at_price)}
          </span>
        )}
      </>
    )
  }

  const Tag = () => {
    if (isPremium) return null

    if (isSoldOut)
      return (
        <div className="absolute z-10 text-body3 font-bold text-text-disabled bg-brand-polar-white px-2 py-0.5 m-2 rounded-sm">
          SOLD OUT
        </div>
      )

    if (isOnSale)
      return (
        <div
          className={cn(
            'absolute z-10 text-body3 font-bold  bg-brand-polar-white px-2 py-0.5 m-2 rounded-sm',
            isPromoOff ? 'text-promo-green' : 'text-text-sale'
          )}
        >
          {isPromoOff ? 'PROMO' : 'SALE'}
        </div>
      )

    return null
  }

  return (
    <div className="w-full cursor-pointer relative">
      <Tag />
      <Link href={`/products${link}`} prefetch={false}>
        <div className="relative" onClick={handleClick}>
          <Image
            src={
              images?.[0]?.url ||
              images?.[0]?.src ||
              '/product-img-placeholder.svg'
            }
            alt={name}
            width={336}
            height={336}
            layout="responsive"
            placeholder="blur"
            blurDataURL={PlaceholderImg.blurDataURL}
            sizes="(min-width: 1024px) 448px, 768px"
            unoptimized
          />
        </div>
      </Link>
      <div className="relative flex flex-col">
        <Link href={`/products${link}`} passHref prefetch={false}>
          <div className="font-normal text-text-subdued uppercase text-body3 mt-3">
            {brand || 'Hoskings Jewellers'}
          </div>
          <p className="font-normal text-text-subdued text-label mt-1">
            {name}
          </p>
          <div className="text-label text-text-subdued font-bold mt-1">
            {!isSoldOut && (
              <div className="text-label text-text-subdued font-bold mt-1">
                <DisplayPrice />
              </div>
            )}
          </div>
          {!isOnSale && <ProductDiscountMessage namedTags={namedTags} />}
        </Link>
        {!!isSoldOut && (
          <Link
            href={`/products${link}?support_menu_open=true`}
            passHref
            prefetch={false}
          >
            <div className="flex items-center gap-3">
              <ArrowRight className="h-4 w-4 text-brand-dark-grey group-hover:text-accent-3" />
              <div className="text-label text-text-subdued font-bold hover:opacity-75 underline">
                ENQUIRE NOW
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

export default ProductCard
