import { algoliaClient } from '@lib/algolia'

async function getAllForIndex(
  indexName: string,
  attributesToRetrieve: string[] = ['handle'],
  filters?: string
) {
  let hits: any[] = []
  const client = algoliaClient()
  const index = client.initIndex(indexName)
  await index.browseObjects({
    attributesToRetrieve,
    batch: (batch) => {
      hits = hits.concat(batch)
    },
    filters,
  })
  return hits
}

export default getAllForIndex
