import { ChangeEvent, useEffect, useState } from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import s from './CartItem.module.css'
import { useUI } from '@components/ui/context'
import type { LineItem } from '@commerce/types/cart'
import usePrice from '@framework/product/use-price'
import useUpdateItem from '@framework/cart/use-update-item'
import useRemoveItem from '@framework/cart/use-remove-item'
import Quantity from '@components/ui/Quantity'
import { useUpdateCart } from '@framework/cart'

type ItemOption = {
  name: string
  nameId: number
  value: string
  valueId: number
}

const placeholderImg = '/product-img-placeholder.svg'

const CartItem = ({
  item,
  variant = 'default',
  currencyCode,
  setGiftWrappingNote,
  giftWrappingNote,
  giftWrappingRemoveHandler,
  promoRemovePrice,
  freeProductRemovedCallback,
  ...rest
}: {
  variant?: 'default' | 'display'
  item: LineItem
  currencyCode: string
  setGiftWrappingNote?: any
  giftWrappingNote?: string
  giftWrappingRemoveHandler?: void
  promoRemovePrice?: {
    collectionTag: string
    productType: 'percent' | 'free' | 'priceoff'
    pecentageOff?: number
    priceOff?: number
    minSpend?: {
      currentSpend: number
      minSpendValue: number
    }
    promoHandle: string
    promoRemovePrice: number
    lineItem: any
  }[]
  freeProductRemovedCallback?: any
}) => {
  const { closeSidebarIfPresent } = useUI()
  const [removing, setRemoving] = useState(false)
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem({ item })
  const [isAddGiftNote, setIsAddGiftNote] = useState(false)
  const [discountedItem, setDiscountedItem] = useState<number>(0)
  const [itemPrice, setItemPrice] = useState(0)
  const [isDiscountedProduct, setIsDiscountedProduct] = useState(false)
  const [disableClick, setDisableClick] = useState(false)

  const updateCart = useUpdateCart()
  const promoItem = promoRemovePrice?.find(
    (promo) => promo.lineItem?.product === item
  )

  let linePrice = item.variant.price * item.quantity
  let baseAmount = linePrice
  let discountMessage = null
  let discountAmount = null

  const isGiftWrapping = item.variant.barcode === 'giftWrapping' ? true : false
  const quantityAvailable = item?.variant?.quantityAvailable;
  const isMaximumQuantity = quantityAvailable && quantity >= quantityAvailable ? true : false;

  if (
    item.variant.listPrice &&
    item.variant.listPrice * item.quantity > linePrice
  ) {
    baseAmount = item.variant.listPrice * item.quantity
  }

  if (item && item.discountAllocations && item.discountAllocations.length > 0) {
    discountMessage = item?.discountAllocations[0]?.discountApplication?.title
    discountAmount = item?.discountAllocations[0]?.allocatedAmount?.amount

    if (
      discountAmount &&
      parseFloat(discountAmount) &&
      !item.variant.isPromoOff
    ) {
      linePrice = linePrice - parseFloat(discountAmount)
    }
  }

  useEffect(() => {
    const updateQuantity = async () => {
      if (quantity <= quantityAvailable) return;
      setQuantity(quantityAvailable);
      await updateItem({ quantity: quantityAvailable });
    };
    updateQuantity();
  }, [quantityAvailable])

  useEffect(() => {
    if (promoRemovePrice && promoRemovePrice.length > 0) {
      const result = promoRemovePrice.filter(
        (product) => product.promoHandle === item.path
      )

      const itemToDiscount = result[0]?.promoRemovePrice
      if (itemToDiscount) {
        setDiscountedItem(itemToDiscount)
      } else {
        setDiscountedItem(0)
      }

      setIsDiscountedProduct(true)

      if (result.length > 0 && promoRemovePrice) {
        setItemPrice(promoRemovePrice[0]?.promoRemovePrice)
      } else {
        setItemPrice(0)
        setIsDiscountedProduct(false)
      }
    }

    if (promoRemovePrice) {
      if (promoRemovePrice.length === 0) {
        setItemPrice(0)
        setIsDiscountedProduct(false)
        setDiscountedItem(0)
      }
    }
  }, [promoRemovePrice])

  const searchFilter = 'search:hidden'

  useEffect(() => {
    if (item.variant.productTags?.includes(searchFilter)) {
      setDisableClick(true)
    }
  }, [])

  const { price, basePrice }: { price: string; basePrice?: string | null } =
    usePrice({
      amount: linePrice,
      baseAmount,
      currencyCode,
    })

  const handleChange = async ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(value))
    await updateItem({ quantity: Number(value) })
  }

  const increaseQuantity = async (n = 1) => {
    const val = Number(quantity) + n
    if (n > 0 && isMaximumQuantity) return;
    setQuantity(val)
    await updateItem({ quantity: val })
  }

  const handleRemove = async () => {
    let freeItemRemoved

    const filterPromoProductRemoved = promoRemovePrice?.find((promoItem) => {
      if (promoItem?.lineItem?.product?.slug === item.path) {
        return true
      }

      return false
    })?.lineItem?.product?.slug

    if (filterPromoProductRemoved) {
      freeItemRemoved = filterPromoProductRemoved
    } else {
      freeItemRemoved = null
    }

    setRemoving(true)

    try {
      await removeItem(item)
      freeProductRemovedCallback(freeItemRemoved)
      setRemoving(false)
    } catch (error) {
      setRemoving(false)
    }
  }

  const giftWrappingHandleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item)
      setGiftWrappingNote('')
      await updateCart({
        note: '',
      })
    } catch (error) {
      setRemoving(false)
    }
  }

  // TODO: Add a type for this
  const options = (item as any).options

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity])

  return (
    <li className="py-[24px] first:pt-0">
      <div className="flex flex-row space-x-4 pt-[0px]">
        {/* img col */}
        <div
          className={`${
            disableClick
              ? 'pointer-events-none relative overflow-hidden z-0'
              : 'relative overflow-hidden cursor-pointer z-0'
          }`}
        >
          <Link href={`/products/${item.path}`}>
            <Image
              onClick={() => closeSidebarIfPresent()}
              className={s.productImage}
              width={90}
              height={90}
              src={item.variant.image?.url || placeholderImg}
              alt={item.variant.image?.altText || 'Product Image'}
              unoptimized
            />
          </Link>
        </div>
        <div className="flex-1 flex flex-col">
          <div
            className={`${
              disableClick
                ? 'flex-1 flex text-base justify-between pointer-events-none'
                : 'flex-1 flex text-base justify-between cursor-pointer'
            }`}
          >
            <Link href={`/products/${item.path}`} legacyBehavior>
              <div>
                <a>
                  <span
                    className={s.productName}
                    onClick={() => closeSidebarIfPresent()}
                  >
                    {item.name}
                  </span>
                </a>
                {/* options */}
                {options && options.length > 0 && (
                  <div className="flex items-center py-1">
                    {options.map((option: ItemOption, i: number) => {
                      const optionsLabel = (option: string) => {
                        switch (option) {
                          case 'Denominations':
                            return 'Gift card value'
                          default:
                            return option
                        }
                      }
                      return (
                        <div
                          key={`${item.id}-${option.name}`}
                          className="text-sm text-[#939393] inline-flex items-center"
                        >
                          {optionsLabel(option.name)}:
                          {option.name === 'Color' ? (
                            <span
                              className="w-5 h-5 px-1 rounded-full inline-flex items-center justify-center overflow-hidden"
                              style={{
                                backgroundColor: `${option.value}`,
                              }}
                            ></span>
                          ) : (
                            <span className="h-5 px-1 inline-flex items-center justify-center overflow-hidden">
                              {option.value}
                            </span>
                          )}
                          {i === options.length - 1 ? (
                            ''
                          ) : (
                            <span className="mr-1">;</span>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </Link>

            {variant === 'display' && (
              <div className="text-sm tracking-wider">{quantity}x</div>
            )}

            <div className="flex flex-col justify-between space-y-2 text-sm font-semibold pl-2">
              {basePrice ? (
                <div className="flex flex-col">
                  {!promoRemovePrice ? (
                    <span
                      className={
                        item.variant.isPromoOff
                          ? s.discountPriceGreen
                          : s.discountPrice
                      }
                    >
                      {price}
                    </span>
                  ) : (
                    <span
                      className={
                        promoRemovePrice.length > 0
                          ? s.discountPriceGold
                          : s.discountPrice
                      }
                    >
                      {price === '$0.00' ? 'Free' : price}
                    </span>
                  )}

                  <span className={s.basePrice}>{basePrice}</span>
                </div>
              ) : (
                <span className="mt-1">
                  {price === '$0.00' ? 'Free' : price}
                </span>
              )}
            </div>
          </div>

          {/* quantity control */}
          {variant === 'default' && (
            <Quantity
              value={quantity}
              isMaximumQuantity={isMaximumQuantity}
              handleRemove={handleRemove}
              giftWrappingHandleRemove={giftWrappingHandleRemove}
              handleChange={handleChange}
              increase={() => increaseQuantity(1)}
              decrease={() => increaseQuantity(-1)}
              isGiftWrapping={isGiftWrapping}
              isDiscountedProduct={isDiscountedProduct}
            />
          )}
        </div>
      </div>

      {isGiftWrapping && (
        <div>
          {!isAddGiftNote && (
            <div
              className="w-max mt-1 cursor-pointer"
              onClick={() => setIsAddGiftNote(true)}
            >
              {giftWrappingNote && giftWrappingNote.trim().length > 0 ? (
                <p className="text-13">Edit gift note</p>
              ) : (
                <p className="text-13">Add a gift note</p>
              )}

              <div className="border-b border-brand-dark-grey opacity-60 -mt-[3px]"></div>
            </div>
          )}

          {isAddGiftNote && (
            <textarea
              className="bg-white w-full p-3 mt-3 text-13 border border-ui-grey-25 rounded-[4px] resize-none focus:outline-none text-ui-grey-50 placeholder-ui-grey-50"
              name=""
              id=""
              rows={4}
              placeholder="Write a note and we’ll attach it to the gift..."
              resize-none
              onChange={(e) => setGiftWrappingNote(e.target.value)}
              value={giftWrappingNote}
            ></textarea>
          )}
        </div>
      )}
      {/*{discountMessage && (*/}
      {/*  <div className={`${s.discountPrice} flex flex-col justify-between space-y-2 text-sm pl-2`}>*/}
      {/*    {discountMessage}*/}
      {/*  </div>*/}
      {/*)}*/}
    </li>
  )
}

export default CartItem
