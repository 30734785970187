// const { config } = require('./../commerce/config')
// const { defaultLocale } = config

const defaultLocale = 'en-AU'

const processLocale = (locale: string | undefined) => {
  let processLocale = locale || defaultLocale

  return processLocale
}

interface LOCALE_DATA {
  name: string
  code: string
  value: string
  optionLabel: string
  selectionLabel: string
  customMessage?: string
  currency: { name: string; value: string }[]
  img: {
    filename: string
    alt: string
  }
}

export const LOCALES_MAP: Record<string, LOCALE_DATA> = {
  'en-us': {
    name: 'United States',
    code: 'US',
    value: 'en-us',
    optionLabel: 'USA / Canada',
    selectionLabel: 'US / CA store',
    currency: [
      {
        name: '$ USD',
        value: 'US',
      },
    ],
    customMessage:
      '*Now shipping to USA and Canada from the European store. Prices in € EUR and freight calculated at checkout.',
    img: {
      filename: 'flag-en-us.svg',
      alt: 'US Flag',
    },
  },
  'en-au': {
    name: 'Australia',
    code: 'AU',
    value: 'en-au',
    optionLabel: 'Australia',
    selectionLabel: 'Australian store',
    currency: [
      {
        name: '$ AUD',
        value: 'AU',
      },
    ],
    img: {
      filename: 'flag-en-us.svg',
      alt: 'US Flag',
    },
  },
  'en-eu': {
    name: 'Europe',
    code: 'EU',
    value: 'en-eu',
    optionLabel: 'Europe',
    selectionLabel: 'Euorpean store',
    currency: [
      {
        name: '€ EUR',
        value: 'EU',
      },
    ],
    img: {
      filename: 'flag-en-us.svg',
      alt: 'US Flag',
    },
  },
  'en-gb': {
    name: 'United Kingdom',
    code: 'GB',
    value: 'en-gb',
    optionLabel: 'United Kingdom (excl. Northern Island)',
    selectionLabel: 'UK store',
    currency: [
      {
        name: '£ GBP',
        value: 'GB',
      },
    ],
    img: {
      filename: 'flag-en-us.svg',
      alt: 'US Flag',
    },
  },
}

// export const getConfigByLocale = (
//   locale: string | undefined,
//   configName: string
// ) => {
//   const { context } = config
//   const currentLocale = processLocale(locale)
//   const currentContext = context?.[currentLocale]

//   return currentContext?.[configName]
// }

export default processLocale
