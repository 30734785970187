import { Check } from '@components/icons'
import ArrowRightWhite from '@components/icons/ArrowRightWhite'
import { Button, useUI } from '@components/ui'
import cn from 'clsx'
import { useRouter } from 'next/router'
import React, { FC, Fragment, useState } from 'react'

interface Props {}

const SubscribeForm: FC<Props> = () => {
  const { locale } = useRouter()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { formFocused, formUnfocused } = useUI()

  const handleOnChange = (e: React.SyntheticEvent<EventTarget>) => {
    const value = (e.target as HTMLInputElement).value
    setEmail(value)
  }

  const handleSubscription = async () => {
    setLoading(true)
    setError(false)
    setMessage('')
    if (
      email &&
      email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      try {
        const response = await fetch('/api/customer/subscribe', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, locale }),
        })
        const result = await response.json()
        if (result?.acceptsMarketing) {
          setMessage('You have been successfully subscribed')
          setLoading(false)
          setSuccess(true)
          //newsletterSubscriptionComplete()
        } else {
          setError(true)
          setMessage(
            'There was an issue subscribing you. Please try again later'
          )
          setLoading(false)
        }
      } catch (err) {
        setError(true)
        setMessage('There was an issue subscribing you. Please try again later')
        setLoading(false)
      }
    } else {
      setError(true)
      setMessage('Please enter a valid email')
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <div className="flex items-stretch">
        <input
          className="appearance-none bg-transparent border-b border-ui-grey-25 w-full focus:outline-none body-2 text-brand-dark-grey mr-3"
          type="email"
          placeholder="Your email address"
          value={email}
          onChange={handleOnChange}
        />
        {success ? (
          <div className="rounded px-2 py-2.5 flex justify-center items-center bg-accent-7">
            <Check fill="#fff" />
          </div>
        ) : (
          <Button
            className="rounded"
            variant="small"
            onClick={handleSubscription}
            loading={loading}
            disabled={loading}
          >
            <span>
              <ArrowRightWhite fill={loading ? '#272727' : '#fff'} />
            </span>
          </Button>
        )}
      </div>
      {message && (
        <div
          className={cn('mt-4 body ', {
            'text-successGreen': success,
            'text-errorRed': error,
          })}
        >
          {message}
        </div>
      )}
    </Fragment>
  )
}

export default SubscribeForm
