const PayPal = ({ ...props }) => {
  return (
    <svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path opacity="0.07" d="M35 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.4 24 3 24H35C36.7 24 38 22.7 38 21V3C38 1.3 36.6 0 35 0Z" fill="black"/>
      <path d="M37 3V21C37 22.1 36.1 23 35 23H3C1.9 23 1 22.1 1 21V3C1 1.9 1.9 1 3 1H35C36.1 1 37 1.9 37 3Z" fill="white"/>
      <path d="M23.5768 10.841C23.4675 11.5466 22.9194 11.5466 22.3891 11.5466H22.0875L22.2991 10.2292C22.3118 10.1496 22.3816 10.091 22.4636 10.091H22.6021C22.963 10.091 23.3038 10.091 23.4796 10.293C23.5848 10.4139 23.6165 10.5934 23.5768 10.841ZM23.3461 8.99998H21.3466C21.2097 8.99998 21.0934 9.09781 21.072 9.23061L20.2636 14.2711C20.2476 14.3704 20.3259 14.4604 20.428 14.4604H21.4541C21.5497 14.4604 21.6312 14.392 21.6461 14.2992L21.8756 12.87C21.8967 12.7372 22.0132 12.6394 22.1499 12.6394H22.7825C24.0996 12.6394 24.8599 12.0129 25.0584 10.7708C25.1478 10.2279 25.062 9.80111 24.8034 9.5023C24.5191 9.17384 24.0151 8.99998 23.3461 8.99998Z" fill="#0079C1"/>
      <path d="M9.31533 10.841C9.20602 11.5466 8.65794 11.5466 8.12765 11.5466H7.82602L8.03767 10.2292C8.0503 10.1496 8.12017 10.091 8.20215 10.091H8.34059C8.70151 10.091 9.04232 10.091 9.21814 10.293C9.32332 10.4139 9.35503 10.5934 9.31533 10.841ZM9.0846 8.99998H7.0851C6.94821 8.99998 6.83194 9.09781 6.81054 9.23061L6.00208 14.2711C5.9861 14.3704 6.06421 14.4604 6.16656 14.4604H7.12145C7.25808 14.4604 7.37435 14.3626 7.39575 14.23L7.61411 12.87C7.63525 12.7372 7.75177 12.6394 7.88841 12.6394H8.52105C9.83815 12.6394 10.5984 12.0129 10.7969 10.7708C10.8864 10.2279 10.8005 9.80111 10.5419 9.5023C10.2576 9.17384 9.75359 8.99998 9.0846 8.99998Z" fill="#00457C"/>
      <path d="M13.7266 12.6505C13.6341 13.1883 13.2 13.5495 12.6459 13.5495C12.3683 13.5495 12.1458 13.4615 12.003 13.2953C11.8615 13.1305 11.8081 12.8956 11.8529 12.6343C11.9391 12.1011 12.3804 11.7285 12.9259 11.7285C13.1979 11.7285 13.4186 11.8172 13.5642 11.985C13.7109 12.154 13.7687 12.3902 13.7266 12.6505ZM15.061 10.8184H14.1035C14.0216 10.8184 13.9517 10.8769 13.9388 10.9568L13.8968 11.2198L13.83 11.1245C13.6225 10.8288 13.1603 10.7297 12.6988 10.7297C11.641 10.7297 10.7374 11.5179 10.5616 12.6232C10.4701 13.1746 10.6 13.7015 10.9182 14.0693C11.2103 14.4071 11.6271 14.5478 12.1239 14.5478C12.9767 14.5478 13.4495 14.0095 13.4495 14.0095L13.4067 14.271C13.3907 14.3704 13.4688 14.4604 13.5714 14.4604H14.4335C14.5704 14.4604 14.6867 14.3628 14.7081 14.23L15.2257 11.0077C15.2417 10.9084 15.1634 10.8184 15.061 10.8184Z" fill="#00457C"/>
      <path d="M27.9881 12.6505C27.8956 13.1883 27.4614 13.5495 26.9074 13.5495C26.6298 13.5495 26.4073 13.4615 26.2645 13.2953C26.1227 13.1305 26.0696 12.8956 26.1144 12.6343C26.2005 12.1011 26.6419 11.7285 27.1874 11.7285C27.4594 11.7285 27.68 11.8172 27.8257 11.985C27.9724 12.154 28.0301 12.3902 27.9881 12.6505ZM29.3225 10.8184H28.365C28.283 10.8184 28.2132 10.8769 28.2003 10.9568L28.1583 11.2198L28.0912 11.1245C27.884 10.8288 27.4217 10.7297 26.9603 10.7297C25.9025 10.7297 24.9989 11.5179 24.8231 12.6232C24.7316 13.1746 24.8615 13.7015 25.1796 14.0693C25.4717 14.4071 25.8886 14.5478 26.3854 14.5478C27.2382 14.5478 27.711 14.0095 27.711 14.0095L27.6682 14.271C27.6522 14.3704 27.7303 14.4604 27.8329 14.4604H28.695C28.8319 14.4604 28.9482 14.3628 28.9696 14.23L29.4872 11.0077C29.5032 10.9084 29.4248 10.8184 29.3225 10.8184Z" fill="#0079C1"/>
      <path d="M20.1605 10.8184H19.1981C19.1061 10.8184 19.02 10.8633 18.9684 10.9383L17.6407 12.8604L17.0782 11.0133C17.0429 10.8978 16.9346 10.8184 16.8119 10.8184H15.866C15.7518 10.8184 15.6714 10.9289 15.7083 11.0351L16.7678 14.0932L15.7712 15.4757C15.6931 15.5842 15.7719 15.7342 15.9073 15.7342H16.8686C16.9596 15.7342 17.045 15.6903 17.097 15.6168L20.2974 11.0757C20.3739 10.9669 20.295 10.8184 20.1605 10.8184Z" fill="#00457C"/>
      <path d="M30.4511 9.13854L29.6305 14.2713C29.6145 14.3706 29.6926 14.4606 29.795 14.4606H30.6205C30.7571 14.4606 30.8736 14.3627 30.8948 14.2299L31.704 9.18948C31.72 9.09013 31.6419 9.00016 31.5393 9.00016H30.6158C30.5336 9.00016 30.4637 9.05871 30.4511 9.13854Z" fill="#0079C1"/>
    </svg>
  )
}

export default PayPal
