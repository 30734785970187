import { SWRFetcher } from "../utils/default-fetcher";
import { useHook, useSWRHook } from "../utils/use-hook";
export const fetcher = SWRFetcher;
const fn = (provider)=>{
    var ref;
    return (ref = provider.customer) == null ? void 0 : ref.useCustomerOrders;
};
const useCustomerOrders = (input)=>{
    const hook = useHook(fn);
    return useSWRHook({
        fetcher,
        ...hook
    })(input);
};
export default useCustomerOrders;
