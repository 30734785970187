export const getCustomerOrdersQuery = /* GraphQL */ `
  query getCustomerOrders($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      orders(first: 20) {
        edges {
          node {
            id
            orderNumber
            processedAt
            statusUrl
            currencyCode
            financialStatus
            fulfillmentStatus
            totalPriceV2 {
              amount
              currencyCode
            }
            totalShippingPriceV2 {
              amount
              currencyCode
            }
            shippingAddress {
              firstName
              lastName
              phone
              address1
              city
              province
              country
              zip
            }
            lineItems(first: 10) {
              edges {
                node {
                  title
                  quantity
                  variant {
                    id
                    sku
                    product {
                      handle
                      vendor
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    compareAtPriceV2 {
                      amount
                      currencyCode
                    }
                    image {
                      altText
                      originalSrc
                      transformedSrc(maxWidth: 700)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default getCustomerOrdersQuery;
