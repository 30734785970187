const Logout = ({ ...props }) => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.6875 0.25H6.32812C6.56016 0.25 6.75 0.439844 6.75 0.671875V0.953125C6.75 1.18516 6.56016 1.375 6.32812 1.375H1.6875C1.37812 1.375 1.125 1.62812 1.125 1.9375V12.0625C1.125 12.3719 1.37812 12.625 1.6875 12.625H6.32812C6.56016 12.625 6.75 12.8148 6.75 13.0469V13.3281C6.75 13.5602 6.56016 13.75 6.32812 13.75H1.6875C0.755859 13.75 0 12.9941 0 12.0625V1.9375C0 1.00586 0.755859 0.25 1.6875 0.25ZM11.4961 0.935547L11.2465 1.18516C11.0813 1.35039 11.0813 1.61758 11.2465 1.78281L15.8871 6.40234H6.04688C5.81484 6.40234 5.625 6.59219 5.625 6.82422V7.17578C5.625 7.40781 5.81484 7.59766 6.04688 7.59766H15.8871L11.25 12.2172C11.0848 12.3824 11.0848 12.6496 11.25 12.8148L11.4996 13.0645C11.6648 13.2297 11.932 13.2297 12.0973 13.0645L17.8805 7.29883C18.0457 7.13359 18.0457 6.86641 17.8805 6.70117L12.0938 0.935547C11.9285 0.770312 11.6613 0.770312 11.4961 0.935547Z"
        fill="#464544"
      />
    </svg>
  )
}

export default Logout
