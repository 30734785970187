import useCustomerOrders from "@vercel/commerce/customer/use-customer-orders";
import { getCustomerOrdersQuery, getCustomerToken } from "../utils";
export default useCustomerOrders;
export const handler = {
    fetchOptions: {
        query: getCustomerOrdersQuery
    },
    async fetcher ({ options , fetch  }) {
        const customerAccessToken = getCustomerToken();
        if (customerAccessToken) {
            var ref;
            const data = await fetch({
                ...options,
                variables: {
                    customerAccessToken
                }
            });
            const orders = (ref = data.customer) == null ? void 0 : ref.orders.edges.map(({ node  })=>node);
            return orders;
        }
        return null;
    },
    useHook: ({ useData  })=>{
        return (input)=>{
            return useData({
                swrOptions: {
                    revalidateOnFocus: false,
                    ...input == null ? void 0 : input.swrOptions
                }
            });
        };
    }
};
