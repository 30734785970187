import { ChevronRight } from '@components/icons'
import { useUI } from '@components/ui/context'
import cn from 'clsx'
import { useRouter } from 'next/router'
import s from './ProfileSidebarItem.module.css'

export enum LinkType {
  EMAIL,
  PHONE,
  CHAT,
  OTHERS,
}

const ProfileSidebarItem = ({
  icon,
  link,
  title,
  className = '',
  underline = true,
  showChevRight = true,
  onPress,
  descriptions,
  linkType = LinkType.OTHERS,
}: {
  icon: any
  title: string
  link?: string
  className?: string
  underline?: boolean
  showChevRight?: boolean
  onPress?: () => void
  descriptions?: string[]
  linkType?: LinkType
}) => {
  const { closeSidebarIfPresent } = useUI()
  const { push } = useRouter()
  const Icon = icon

  const item = (
    <div
      className={cn(
        'flex flex-col',
        underline ? 'py-4 border-b border-b-ui-grey-25 ' : 'mb-3'
      )}
    >
      <div
        className={cn(
          'flex flex-row justify-between items-center cursor-pointer'
        )}
        onClick={() => {
          if (onPress) onPress()
        }}
      >
        <div className="flex flex-row space-x-4 items-center">
          <Icon />
          <span className="font-medium">{title}</span>
        </div>
        {showChevRight && <ChevronRight />}
      </div>
      {descriptions && (
        <div className="pt-4 pl-8">
          <ul className="list-inside list-disc">
            {descriptions.map((item, index) => {
              return (
                <li className="font-medium" key={index}>
                  {item}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
  if (!title) return null

  const goToLink = () => {
    closeSidebarIfPresent()
    push(link || '/')
  }

  if (linkType !== LinkType.OTHERS) {
    return (
      <div className={cn(s.item, className)}>
        {link ? <a href={link}>{item}</a> : item}
      </div>
    )
  }

  return (
    <div className={cn(s.root, className)}>
      {link ? <button onClick={goToLink}>{item}</button> : item}
    </div>
  )
}

export default ProfileSidebarItem
