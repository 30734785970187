import { getGeoLocation, getNearestStore } from './geoLocation'

import Script from 'next/script'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import useLocalStorage from './hooks/useLocalStorage'
import reducer, { INITIAL_STATE } from './reducer'
import { Action, State, StoreLocation } from './types'
import { canUseDOM } from './utils/dom'
import getAllStores from '@lib/algolia/get-all-stores'
import { Loader } from '@googlemaps/js-api-loader'

interface StoreSidebarProviderContextProps extends State {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  sortByNearestStore: () => void
  sortByLocation: (lnt: number, lng: number) => void
  setMyStore: (store: StoreLocation) => void
  isMapReady: boolean
}

export const StoreSidebarContextProvider =
  React.createContext<StoreSidebarProviderContextProps>(
    Object.assign({}, INITIAL_STATE, {
      isOpen: false,
      onOpen: () => {},
      onClose: () => {},
      sortByNearestStore: () => {},
      sortByLocation: (lnt: number, lng: number) => {},
      setMyStore: (store: StoreLocation) => {},
      isMapReady: false,
    })
  )

const loader = new Loader({
  apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API!,
  version: 'weekly',
  libraries: ['places'],
  id: '__googleMapsScriptId',
})

function StoreSidebarProvider({ children }: PropsWithChildren<{}>) {
  const [isMapReady, setGoogleMapReady] = useState(false)
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    reducer,
    INITIAL_STATE
  )
  const [isOpen, setIsOpen] = React.useState(false)
  const [storeFromStorage, setStorage] = useLocalStorage(
    'store-finder',
    state?.myStore
  )

  const [stores, setStores] = useState<StoreLocation[]>([])

  useEffect(() => {
    const initStores = async () => {
      const stores = await getAllStores()
      dispatch({
        type: 'LOAD_STORE',
        payload: stores,
      })
      setStores(stores)
    }

    initStores()
  }, [])

  useEffect(() => {
    const initMap = async () => {
      await loader.load()
      setGoogleMapReady(true)
    }
    initMap()
  }, [])

  useEffect(() => {
    if (storeFromStorage && stores?.length > 0) {
      dispatch({ type: 'SET_MY_STORE', payload: storeFromStorage })
    }
  }, [storeFromStorage, stores])

  const handleOnOpen = () => setIsOpen(true)

  const handleOnClose = () => setIsOpen(false)

  const sortByNearestStore = async () => {
    dispatch({
      type: 'REQUEST_SORT_STORE',
    })

    const geoLocation = await getGeoLocation<StoreLocation>()

    if (!geoLocation) return

    const sortedStoreLocations = await getNearestStore<StoreLocation[]>(
      geoLocation
    )

    dispatch({
      type: 'SORT_STORE',
      payload: sortedStoreLocations,
    })
  }

  const sortByLocation = async (lat: number, lng: number) => {
    dispatch({
      type: 'REQUEST_SORT_STORE_BY_LOCATION',
    })

    const geoLocation = { lat, lng }
    const sortedStoreLocations = await getNearestStore<StoreLocation[]>(
      geoLocation
    )

    dispatch({
      type: 'SORT_STORE',
      payload: sortedStoreLocations,
    })
  }

  const setMyStore = (store: StoreLocation) => {
    dispatch({ type: 'SET_MY_STORE', payload: store })
    setStorage(store)
  }

  const values: StoreSidebarProviderContextProps = {
    ...state,
    isOpen,
    onOpen: handleOnOpen,
    onClose: handleOnClose,
    sortByNearestStore,
    sortByLocation,
    setMyStore,
    isMapReady,
  }

  return (
    <StoreSidebarContextProvider.Provider value={values}>
      {children}
    </StoreSidebarContextProvider.Provider>
  )
}

export default StoreSidebarProvider
