import { LeftArrow } from '@components/icons'
import { connectStateResults } from 'react-instantsearch-dom'
import ArrowRight from '../../icons/ArrowRight'

interface Props {
  goToSearchPage: () => void
  searchState: any
  searchResults: any
}

const SearchState = ({ searchResults, searchState, goToSearchPage }: Props) => {
  const hasResults = searchResults && searchResults.nbHits !== 0
  const nbHits = searchResults && searchResults.nbHits
  const query = searchState.query

  if (!query) return <></>

  if (!hasResults) {
    return (
      <>
        <div className="text-brand-dark-grey no-result mx-auto text-center py-20">
          {`Hmm, we couldn't find anything for ${query}`}
          <br />
          Try searching for something else
        </div>
        <style jsx>
          {`
            .no-result {
              max-width: 417px;
            }
          `}
        </style>
      </>
    )
  }

  return (
    <div className="max-w-4xl mx-auto">
      <button
        className="group px-4 body-1 text-brand-dark-grey pb-8 md:pb-10 flex gap-3 items-center hover:text-accent-3"
        onClick={goToSearchPage}
      >
        <ArrowRight className="h-4 w-4 text-brand-dark-grey group-hover:text-accent-3" />

        {`View all results for "${query}"(${nbHits})`}
      </button>
    </div>
  )
}

export default connectStateResults(SearchState)
