const PhoneV2 = ({ className = '', ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M20.0367 14.6227L16.4578 13.0877C15.8074 12.8175 15.0445 13.0043 14.6033 13.5476L13.4382 14.9704C11.5852 13.9617 10.0418 12.4184 9.03281 10.5656L10.4573 9.3988C11.0082 8.94458 11.1896 8.19786 10.9088 7.54114L9.37734 3.96223C9.07148 3.2559 8.30156 2.86862 7.55625 3.04057L4.23645 3.81071C3.50871 3.97664 3 4.61368 3 5.35969C3 13.9828 10.0172 21 18.641 21C19.3874 21 20.0265 20.4924 20.1939 19.765L20.9603 16.4438C21.1301 15.6949 20.7434 14.9285 20.0367 14.6227ZM19.098 19.5129C19.0497 19.7228 18.8574 19.8754 18.6417 19.8754C10.6366 19.8754 4.1257 13.3644 4.1257 5.35934C4.1257 5.14179 4.27455 4.95575 4.48922 4.90653L7.80832 4.13625C7.84348 4.12801 7.87863 4.12417 7.91323 4.12417C8.09723 4.12417 8.26866 4.23294 8.34389 4.40651L9.876 7.9819C9.95742 8.17501 9.90469 8.39649 9.74297 8.53009L7.96758 9.98556C7.76719 10.1508 7.70391 10.432 7.81992 10.6676C8.99344 13.0515 10.9527 15.0094 13.3359 16.1836C13.5694 16.3001 13.8538 16.2374 14.0194 16.0353L15.4745 14.2599C15.6047 14.0995 15.8317 14.0467 16.0194 14.1248L19.5913 15.6562C19.7989 15.7463 19.9132 15.9715 19.8627 16.1912L19.098 19.5129Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PhoneV2
