import useUpdateCustomer from "@vercel/commerce/customer/use-update-customer";
import { CommerceError } from "@vercel/commerce/utils/errors";
import { useCallback } from "react";
import useCustomer from "../customer/use-customer";
import { customerUpdateMutation, getCustomerToken, throwUserErrors } from "../utils";
export default useUpdateCustomer;
export const handler = {
    fetchOptions: {
        query: customerUpdateMutation
    },
    async fetcher ({ input: { firstName , lastName , email , acceptsMarketing  } , options , fetch ,  }) {
        if (!(firstName && lastName && email)) {
            throw new CommerceError({
                message: "A first name, last name and email are required to signup"
            });
        }
        const { customerUpdate  } = await fetch({
            ...options,
            variables: {
                customerAccessToken: getCustomerToken(),
                input: {
                    firstName,
                    lastName,
                    email,
                    acceptsMarketing
                }
            }
        });
        throwUserErrors(customerUpdate == null ? void 0 : customerUpdate.customerUserErrors);
        return null;
    },
    useHook: ({ fetch  })=>()=>{
            const { mutate  } = useCustomer();
            return useCallback(async function updateCustomer(input) {
                const data = await fetch({
                    input
                });
                await mutate(data);
                return data;
            }, [
                fetch,
                mutate
            ]);
        }
};
