import MegaMenu from '@components/common/MegaMenu'
import SidebarLayout from '@components/common/SidebarLayout'
import {
  ChevronRight,
  LegacyFancyArrowLeft,
  ArrowLeft,
  ArrowRightSmall
} from '@components/icons'
import { useUI } from '@components/ui/context'
import { groupNavItems } from '@lib/helpers/group-nav-items'
import { useRouter } from 'next/router'
import { useState } from 'react'

export default function MenuSidebarView({
  menuItems = [],
  onClose,
}: {
  menuItems?: any
  onClose: () => void
}) {
  const { closeSidebar } = useUI()
  const router = useRouter()
  const groupedMenuItems = groupNavItems(menuItems?.data?.slices)
  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const [megaMenuIndex, setMegaMenuIndex] = useState(-1)
  const hideMegaMenu = () => {
    setMegaMenuIndex(-1)
    setShowMegaMenu(false)
  }
  const setMegaMenuVisibility = (value: boolean) => {
    setShowMegaMenu(value)
  }
  return (
    <SidebarLayout handleClose={onClose}>
      <div className="pt-8 pb-10 bg-brand-light-grey w-full">
        <nav>
          {!showMegaMenu && (
            <>
              <div className="text-center body-3-bold py-4 bg-white text-ui-darkest-grey uppercase">
                MENU
              </div>
              <ul className="space-y-4 px-5 py-6">
                {groupedMenuItems.map((nav, index) => {
                  const hasSubMenu = nav.type === 'MegaNav'
                  const openMegaMenu = () => {
                    if (hasSubMenu) {
                      setMegaMenuIndex(index)
                      setShowMegaMenu(true)
                    } else {
                      closeSidebar()
                      router.push(nav.link)
                    }
                  }
                  return (
                    <li
                      className="body text-ui-dark-grey flex justify-between"
                      key={nav.label}
                      onClick={openMegaMenu}
                    >
                      <div>{nav.label}</div>
                      {hasSubMenu && (
                        <div className="mt-1">
                          <ChevronRight />
                        </div>
                      )}
                    </li>
                  )
                })}
              </ul>
            </>
          )}
          {showMegaMenu && (
            <div>
              <div className="relative flex wrapper py-4 bg-white">
                <button onClick={hideMegaMenu}>
                  <ArrowLeft />
                </button>
                <div className="mx-auto body-3-bold text-ui-dark-grey uppercase">
                  {groupedMenuItems[megaMenuIndex]?.label}
                </div>
              </div>
              <button
                className="body-1 text-dark-grey pt-6 px-4 w-full text-left uppercase flex flex-row items-center gap-2"
                onClick={() => {
                  closeSidebar()
                  router.push(groupedMenuItems[megaMenuIndex]?.link)
                }}
              >
                <span>Shop All</span>
                <ArrowRightSmall />
              </button>
              <MegaMenu
                handleMegaMenuVisibility={setMegaMenuVisibility}
                menuItems={groupedMenuItems[megaMenuIndex]?.items}
              />
            </div>
          )}
        </nav>
      </div>
    </SidebarLayout>
  )
}

MenuSidebarView
