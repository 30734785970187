import { ArrowLeft, LegacyCross } from '@components/icons'
import { Button, LoadingDots } from '@components/ui'
import {
  StoreSidebarSearchInput,
  useStoreSidebar,
} from '@lib/store-finder-sidebar'
import { StoreLocation } from '@lib/store-finder-sidebar/types'
import { FC } from 'react'
import StoreCard from './components/StoreCard'

interface Props {
  onClose: () => void
}

const StoreFinderSidebar: FC<Props> = ({ onClose }) => {
  const {
    stores,
    myStore,
    isFetching,
    sortByNearestStore,
    sortByLocation,
    isCurrentLocationFetching,
    setMyStore,
  } = useStoreSidebar()

  const handleSetStore = (store: StoreLocation) => {
    setMyStore(store)
  }

  const handleGetCurrentLocation = () => sortByNearestStore()

  const handleSortByLocation = (lat: number, lng: number) =>
    sortByLocation(lat, lng)

  const handleOnShowNumber = (store: StoreLocation) => {
    // do something
  }

  return (
    <>
      <style jsx global>
        {`
          ::-webkit-scrollbar {
            width: 5px;
          }
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          ::-webkit-scrollbar-thumb {
            background: #888;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>
      <div className="flex flex-col w-full bg-white">
        <div className="p-4 w-full flex justify-between items-center">
          <button
            aria-label="Close"
            className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none"
            onClick={onClose}
          >
            <ArrowLeft className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
          </button>
          <span className="font-semibold text-13 leading-6 text-dark-grey">
            FIND YOUR STORE
          </span>
          <button
            aria-label="Close"
            className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none"
            onClick={onClose}
          >
            <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
          </button>
        </div>
        <div className="p-5 flex flex-col">
          <Button
            fullWidth
            variant="slim"
            onClick={handleGetCurrentLocation}
            loading={isCurrentLocationFetching}
          >
            Use my current location
          </Button>
          <span className="my-2 text-center">or</span>
          <StoreSidebarSearchInput
            onSelect={handleSortByLocation}
            searchScopes={['au']}
          />
          <div className="flex flex-col mt-8">
            <div className=" mb-4">
              <h3 className="font-semibold uppercase text-13">SELECT STORE</h3>
            </div>
            {isFetching ? (
              <LoadingDots />
            ) : (
              stores.map((store, k) => (
                <div key={k} className="mb-4">
                  <StoreCard
                    {...store}
                    isCurrent={store.id === myStore?.id}
                    onSetStore={() => handleSetStore(store)}
                    onShowNumber={() => handleOnShowNumber(store)}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default StoreFinderSidebar
