import { LineItem } from '@commerce/types/cart'
import isPartnerDeliveryLineItem from '@lib/partner-delivery-lineitem'

const splitLineItemsByDeliveryType = (lineItems: LineItem[] | undefined) => {
  const splitItems: {
    partner: LineItem[]
    vendor: LineItem[]
  } = {
    partner: [],
    vendor: [],
  }

  if (!lineItems) return splitItems

  lineItems?.forEach((lineItem: LineItem) => {
    if (isPartnerDeliveryLineItem(lineItem)) {
      splitItems.partner.push(lineItem)
    } else {
      splitItems.vendor.push(lineItem)
    }
  })

  return splitItems
}

export default splitLineItemsByDeliveryType
