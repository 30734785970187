import cn from 'clsx'
import { FC } from 'react'
interface props {
  label: string
  checked?: boolean
  handleChange: (value: boolean) => void
  labelStyling?: string
}

const Checkbox: FC<props> = ({
  label,
  checked,
  handleChange,
  labelStyling,
}) => {
  return (
    <div className="w-full pr-16 laptop:pr-0">
      <label className={cn('containerBox', labelStyling)}>
        {label}
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            handleChange(e.currentTarget.checked)
          }}
        />
        <span className={cn('checkmark')}></span>
        <style jsx>{`
          .containerBox {
            display: block;
            position: relative;
            padding-left: 24px;
            margin: 24px 0px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          .containerBox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            top: 3px;
            left: 0;
            height: 15px;
            width: 15px;
            border: 1px solid #6a6a6a;
            border-radius: 2px;
          }
          .containerBox input:checked ~ .checkmark {
            background-color: #464544;
            border: none;
          }
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }
          .containerBox input:checked ~ .checkmark:after {
            display: block;
          }
          .containerBox .checkmark:after {
            left: 6px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid white;
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          @media (max-width: 768px) {
            .containerBox-mobile {
              padding-left: 0;
            }
            .checkmark-mobile {
              right: 0;
              left: unset;
            }
          }
        `}</style>
      </label>
    </div>
  )
}

export default Checkbox
