const FAQ = ({ className = '', ...props }) => (
  <>
    <svg
      className={className}
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0714 0H1.92857C0.863437 0 0 0.863437 0 1.92857V16.0714C0 17.1366 0.863437 18 1.92857 18H16.0714C17.1366 18 18 17.1366 18 16.0714V1.92857C18 0.863437 17.1366 0 16.0714 0ZM16.7143 16.0714C16.7143 16.4259 16.4259 16.7143 16.0714 16.7143H1.92857C1.57412 16.7143 1.28571 16.4259 1.28571 16.0714V1.92857C1.28571 1.57412 1.57412 1.28571 1.92857 1.28571H16.0714C16.4259 1.28571 16.7143 1.57412 16.7143 1.92857V16.0714ZM9 12.375C8.37868 12.375 7.875 12.8787 7.875 13.5C7.875 14.1213 8.37868 14.625 9 14.625C9.62132 14.625 10.125 14.1213 10.125 13.5C10.125 12.8787 9.62132 12.375 9 12.375ZM9.30817 11.4107H8.66531C8.39905 11.4107 8.18317 11.1948 8.18317 10.9286V10.9133C8.18317 8.08698 11.2946 8.35714 11.2946 6.59776C11.2946 5.79355 10.581 4.98214 8.98674 4.98214C7.81578 4.98214 7.20824 5.36983 6.60773 6.13495C6.45071 6.33504 6.16359 6.37582 5.95491 6.23041L5.4272 5.86278C5.2012 5.70532 5.15158 5.38983 5.32093 5.17263C6.17376 4.07869 7.18562 3.375 8.98674 3.375C11.0889 3.375 12.9017 4.57035 12.9017 6.59776C12.9017 9.30636 9.79031 9.16312 9.79031 10.9133V10.9286C9.79031 11.1948 9.57443 11.4107 9.30817 11.4107Z"
        fill="#464544"
      />
    </svg>
  </>
)

export default FAQ
