const customerUpdateMutation = /* GraphQL */ `
  mutation customerUpdate(
    $input: CustomerUpdateInput!
    $customerAccessToken: String!
  ) {
    customerUpdate(
      customer: $input
      customerAccessToken: $customerAccessToken
    ) {
      customer {
        id
        firstName
        lastName
        email
        acceptsMarketing
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;
export default customerUpdateMutation;
